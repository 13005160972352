const MAX_ADULTS = 10;
const MIN_ADULTS = 1;
const MAX_ROOMS = 5;
const MIN_ROOMS = 1;

const areRoomsAndAdultsValid = ({ numberOfAdults, numberOfRooms } = {}) => {
  if (!numberOfAdults || !numberOfRooms) {
    return false;
  }

  if (numberOfAdults > MAX_ADULTS) {
    return false;
  }

  if (numberOfRooms > MAX_ROOMS) {
    return false;
  }

  if (numberOfRooms > numberOfAdults) {
    return false;
  }

  return true;
};

export { MAX_ADULTS, MAX_ROOMS, MIN_ROOMS, MIN_ADULTS, areRoomsAndAdultsValid };
