import addDays from 'date-fns/addDays';

import { DATE_FORMAT } from '../../../skyscanner-application/i18n';

import type { Maybe } from '../../types';

const defaultData = {
  minPrice: 0,
  averagePrice: 0,
};

const calculatePrice = (
  checkIn: Date,
  checkOut: Maybe<Date>,
  dateData: any,
  nightCount: number,
  formatDate: Function,
) => {
  if (dateData && checkOut) {
    let startDate: Date = checkIn;
    let dateSelector;
    const dataSet = [];
    for (let i = 0; i < nightCount; i += 1) {
      dateSelector = formatDate(startDate, DATE_FORMAT.NON_LOCALISED_SHORT);
      const datePrice = dateData[dateSelector]?.median || 0;
      if (!datePrice) {
        return defaultData;
      }
      dataSet.push(datePrice);
      startDate = addDays(startDate, 1);
    }
    const filteredDataLength = dataSet.length;
    if (filteredDataLength > 0) {
      return {
        minPrice: dataSet.reduce((acc, cur) => (acc < cur ? acc : cur)),
        averagePrice: parseInt(
          (
            dataSet.reduce((acc, cur) => acc + cur) / filteredDataLength
          ).toFixed(0),
          10,
        ),
      };
    }
  }
  return defaultData;
};
export default calculatePrice;
