const DbookFunnelStates = {
  LOADING: 'LOADING',
  CHECKOUT: 'CHECKOUT',
  SUBMITTING: 'SUBMITTING',
  BOOKING: 'BOOKING',
  CONFIRMED: 'CONFIRMED',
  ACCEPTED: 'ACCEPTED',
  UNCONFIRMED: 'UNCONFIRMED',
  THREE_DS: 'THREE_DS',
  DEVICE_COLLECT: 'DEVICE_COLLECT',
};

const MessageStates = {
  CHECKOUT_ERROR: 'CHECKOUT_ERROR',
  PRICE_HAS_CHANGED: 'PRICE_HAS_CHANGED',
  PRICE_HAS_CHANGED_NO_RATE: 'PRICE_HAS_CHANGED_NO_RATE',
  ROOM_UNAVAILABLE: 'ROOM_UNAVAILABLE',
  POLICY_HAS_CHANGED: 'POLICY_HAS_CHANGED',
};

const FinalBookingStates = [
  DbookFunnelStates.UNCONFIRMED,
  DbookFunnelStates.CONFIRMED,
  DbookFunnelStates.ACCEPTED,
  DbookFunnelStates.FAILED,
];

const isFinalState = (state) => FinalBookingStates.indexOf(state) !== -1;

export { DbookFunnelStates, MessageStates, FinalBookingStates, isFinalState };
