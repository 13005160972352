import { Component as ReactComponent } from 'react';
import type { ComponentType, ReactNode } from 'react';

import displayName from '../../components/display-name';
import { MetricsShape } from '../../components/shapes';

import type { MetricsType } from '../types';

interface ContextProps {
  metrics: MetricsType;
}

type Props = {
  metrics: MetricsType;
  children: ReactNode;
};

const ContextTypes = {
  metrics: MetricsShape,
};

class MetricsContext extends ReactComponent<Props> {
  static childContextTypes = ContextTypes;

  getChildContext() {
    const { metrics } = this.props;
    return { metrics };
  }

  render() {
    return <> {this.props.children} </>;
  }
}

const withMetrics = <P extends ContextProps>(Component: ComponentType<P>) => {
  const component = (props: Omit<P, 'metrics'>, { metrics }: ContextProps) => (
    // @ts-ignore we expect that metrics can be overwritten in the unit tests
    <Component metrics={metrics} {...(props as P)} />
  );

  component.contextTypes = ContextTypes;

  component.displayName = displayName(Component);

  return component;
};

export default MetricsContext;
export { withMetrics };
