import { Component } from 'react';

import {
  ALERT_TYPES,
  BpkBannerAlertExpandable,
  withBannerAlertState,
} from '@skyscanner/backpack-web/bpk-component-banner-alert';
import TickIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/tick';
import BpkInput, {
  INPUT_TYPES,
} from '@skyscanner/backpack-web/bpk-component-input';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import {
  Paragraph,
  ParagraphLabel2,
  ParagraphSm,
} from '../../../../../../components/HotelsCommon/Text';
import stayLength from '../../../../../common/src/services/guest-stay/stay-length';
import { DATE_FORMAT, withI18n } from '../../../skyscanner-application/i18n';
import ScrollableRangeCalendar from '../../ScrollRangeCalendar/ScrollableRangeCalendar';
import { withPriceData } from '../PriceDataProvider';

import ApplyFooter from './ApplyFooter';
import PriceInfoDialog from './PriceInfoDialog';
import calculatePrice from './calculatePrice';

import type { I18nShape } from '../../../skyscanner-application/i18n';
import type { CalendarPriceDataShape, Maybe } from '../../types';

import STYLES from './ScrollableDateRangeSelector.scss';

type Props = {
  i18n: I18nShape;
  onApply: Function;
  onClose: Function;
  startDate: Date;
  endDate: Date;
  isWithBannerAlert?: boolean;
  isWithPriceInfo?: boolean;
  priceData: Maybe<CalendarPriceDataShape>;
};

type State = {
  startDate: Date;
  endDate: Date;
};

const defaultProps = {
  isWithBannerAlert: false,
  isWithPriceInfo: undefined,
};

const cls = cssModules(STYLES);

const BannerAlertExpandableState = withBannerAlertState(
  BpkBannerAlertExpandable,
);

class ScrollableDateRangeSelector extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    const { endDate, startDate } = props;
    this.state = {
      startDate,
      endDate,
    };
  }

  onSubmit = () => {
    const { onApply, onClose } = this.props;
    const { endDate, startDate } = this.state;

    onClose();
    onApply(startDate, endDate);
  };

  onDateRangeChange = (startDate: Date, endDate: Date) => {
    this.setState({ startDate, endDate });
  };

  render() {
    const { endDate, startDate } = this.state;
    const {
      i18n: { formatDate: formatDateI18n, translate },
      isWithBannerAlert,
      isWithPriceInfo,
      priceData,
    } = this.props;
    const nightCount = stayLength({
      checkIn: startDate,
      // @ts-ignore
      checkOut: endDate,
    });
    const { averagePrice, minPrice } = calculatePrice(
      startDate,
      endDate,
      priceData,
      nightCount,
      formatDateI18n,
    );
    const showCalculatedPrice = averagePrice !== 0;

    return (
      <div className={cls('ScrollableDateRangeSelector')}>
        <div className={cls('ScrollableDateRangeSelector__header')}>
          {isWithBannerAlert && (
            <div className={cls('ScrollableDateRangeSelector__alert')}>
              <Paragraph>
                <BannerAlertExpandableState
                  message={translate('DateRangeSelector_banner_title')}
                  type={ALERT_TYPES.NEUTRAL}
                  toggleButtonLabel={translate('Button_viewMore')}
                >
                  {translate('DateRangeSelector_banner_content')}
                </BannerAlertExpandableState>
              </Paragraph>
            </div>
          )}
          {isWithPriceInfo && (
            <div className={cls('ScrollableDateRangeSelector__subtitle')}>
              <ParagraphSm>
                {translate('HotelStay_modal_calendar_estimated_prices')}
              </ParagraphSm>
              <PriceInfoDialog headerIcon={<TickIcon />} dismissible={false} />
            </div>
          )}
        </div>

        <div className={cls('ScrollableDateRangeSelector__content')}>
          <div className={cls('ScrollableDateRangeSelector__dateRange')}>
            <div
              className={cls(
                'ScrollableDateRangeSelector__date',
                'ScrollableDateRangeSelector__date--checkIn',
              )}
            >
              <ParagraphLabel2>
                {translate('SelectionSummary_label_checkIn')}
              </ParagraphLabel2>
              <BpkInput
                id="scrollableDateCheckInId"
                type={INPUT_TYPES.text}
                name="scrollableDateCheckInId"
                className={cls('ScrollableDateRangeSelector__date--input')}
                value={
                  startDate
                    ? formatDateI18n(
                        startDate,
                        DATE_FORMAT.MONTH_DAY_ABBR,
                      ).replace(',', '')
                    : translate('HotelStay_label_checkIn')
                }
                placeholder={translate('HotelStay_label_checkIn')}
                readOnly
              />
            </div>
            <div
              className={cls(
                'ScrollableDateRangeSelector__date',
                'ScrollableDateRangeSelector__date--checkOut',
              )}
            >
              <ParagraphLabel2>
                {translate('SelectionSummary_label_checkOut')}
              </ParagraphLabel2>
              <BpkInput
                id="scrollableDateCheckOutId"
                type={INPUT_TYPES.text}
                name="scrollableDateCheckOutId"
                className={cls('ScrollableDateRangeSelector__date--input')}
                value={
                  endDate
                    ? formatDateI18n(
                        endDate,
                        DATE_FORMAT.MONTH_DAY_ABBR,
                      ).replace(',', '')
                    : translate('HotelStay_label_checkOut')
                }
                placeholder={translate('HotelStay_label_checkOut')}
                readOnly
                disabled={!endDate}
              />
            </div>
          </div>
          <ScrollableRangeCalendar
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={this.onDateRangeChange}
          />
          <ApplyFooter
            checkOut={endDate}
            disabled={
              !startDate ||
              !endDate ||
              (startDate === this.props.startDate &&
                endDate === this.props.endDate)
            }
            showCalculatedPrice={showCalculatedPrice}
            averagePrice={averagePrice}
            nightCount={nightCount}
            minPrice={minPrice}
            onApply={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

export default withI18n(withPriceData(ScrollableDateRangeSelector));
