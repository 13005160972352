import type { KeyboardEvent } from 'react';

import { withRouter } from 'react-router-dom';

import type { LocationShape, HistoryShape } from '../types';

type Props = {
  history: HistoryShape;
  location: LocationShape;
  href: string;
  render: Function;
  onClick?: Function;
  target?: string;
};

const isModifiedEvent = (event: KeyboardEvent) =>
  event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;

// Copy from https://github.com/ReactTraining/react-router/blob/6e9d819dd579715a6cb7502f31bdb25e206c82d6/packages/react-router-dom/modules/Link.js#L18-L34
// We copied the logic from React Router Link in order to provide a component
// that could render navigation links with the specified logic and get it to be reusable
const RouteLink = ({
  history,
  href,
  location,
  onClick = undefined,
  render,
  target = undefined,
}: Props) => (
  <>
    {render((event: any, ...rest: Props[]) => {
      try {
        if (onClick) onClick(event, ...rest);
      } catch (ex) {
        event.preventDefault();
        throw ex;
      }
      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        (!target || target === '_self') && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();
        history.push(
          { ...location, hash: href },
          {
            pushHashByRouteLink: true,
          },
        );
      }
    }, href)}
  </>
);

export default withRouter(RouteLink);
