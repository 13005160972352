import { Component as ReactComponent } from 'react';
import type { ComponentType, ReactNode } from 'react';

import displayName from '../../components/display-name';

import ElementEventTrackerShape from './element-event-tracker-shape';

import type { ElementEventTrackerReturn } from '../types';

interface ContextProps {
  elementEventTracker: ElementEventTrackerReturn;
}

type Props = {
  elementEventTracker: ElementEventTrackerReturn;
  children: ReactNode;
};

const ContextTypes = {
  elementEventTracker: ElementEventTrackerShape,
};

class ElementEventTrackerContext extends ReactComponent<Props> {
  static childContextTypes = ContextTypes;

  getChildContext() {
    const { elementEventTracker } = this.props;
    return { elementEventTracker };
  }

  render() {
    return <> {this.props.children} </>;
  }
}

const withElementEventTracker = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (
    props: Omit<P, 'elementEventTracker'>,
    { elementEventTracker }: ContextProps,
  ) => (
    // @ts-ignore we expect that elementEventTracker can be overwritten in the unit tests
    <Component elementEventTracker={elementEventTracker} {...(props as P)} />
  );

  component.contextTypes = ContextTypes;

  component.displayName = displayName(Component);

  return component;
};

export default ElementEventTrackerContext;
export { withElementEventTracker };
