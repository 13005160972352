import type { MouseEventHandler } from 'react';

import BpkModal from '@skyscanner/backpack-web/bpk-component-modal';

import { HASH_CHECK_IN_CHECK_OUT } from '../../../../services/routes';
import { withI18n } from '../../../../skyscanner-application/i18n';
import { getApplicationElement } from '../../../Common/ApplicationElements';
import HashRoute from '../../../HashRoute';
import RouteLink from '../../../RouteLink';
import ScrollableDateRangeSelector from '../../ScrollableDateRangeSelector';
import DateRange from '../DateRange';

import type { I18nShape } from '../../../../skyscanner-application/i18n';

type Props = {
  i18n: I18nShape;
  startDate: Date;
  endDate: Date;
  onApply: Function;
  isWithBannerAlert?: boolean;
};

type RenderProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  onClose: () => void;
};

const onOpenModal = () => {
  window.name = 'DVSearchModalIsOpen';
};

const FullScreenScrollableDateRangeSelector = ({
  endDate,
  i18n,
  isWithBannerAlert = false,
  onApply,
  startDate,
}: Props) => (
  <>
    <RouteLink
      href={HASH_CHECK_IN_CHECK_OUT}
      onClick={onOpenModal}
      render={(onClickLink: MouseEventHandler<HTMLDivElement>) => (
        <DateRange
          onClickLink={onClickLink}
          checkInDate={startDate}
          checkOutDate={endDate}
        />
      )}
    />
    <HashRoute
      hash={HASH_CHECK_IN_CHECK_OUT}
      render={({ onClose }: RenderProps) => (
        <BpkModal
          isOpen
          id="date-range-selector-modal"
          onClose={onClose}
          getApplicationElement={getApplicationElement}
          padded={false}
          title={i18n.translate('Explore_label_Dates')}
        >
          <ScrollableDateRangeSelector
            startDate={startDate}
            endDate={endDate}
            onApply={onApply}
            onClose={onClose}
            isWithBannerAlert={isWithBannerAlert}
          />
        </BpkModal>
      )}
    />
  </>
);

export default withI18n(FullScreenScrollableDateRangeSelector);
