import {
  DbookFunnelStates,
  MessageStates,
} from '../../screens/DbookFunnelPage/booking-flow/States';
import {
  CREATE_FAIL,
  INVALID_BRAINTREE_TOKEN,
  INVALID_DATA,
  UNEXPECTED_ERROR,
  PAYMENT_TOKEN_FAILED,
} from '../../screens/DbookFunnelPage/booking-flow/statham/errorMessage';

export const ACTION_TYPE = {
  UNSET_ACTION_TYPE: 'UNSET_ACTION_TYPE',

  PAGE_LOAD: 'PAGE_LOAD',
  SEARCH_STARTED: 'SEARCH_STARTED',
  SEARCH_FINISHED: 'SEARCH_FINISHED',
  DAY_VIEW_SEARCH: 'DAY_VIEW_SEARCH',
  HOME_PAGE_SEARCH: 'HOME_PAGE_SEARCH',
  DAY_VIEW_HOTEL_CARD_CLICKED: 'DAY_VIEW_HOTEL_CARD_CLICKED',
  DAY_VIEW_HOTEL_CARD_DBOOK_CLICKED: 'DAY_VIEW_HOTEL_CARD_DBOOK_CLICKED',
  DAY_VIEW_HOTEL_CARD_META_CLICKED: 'DAY_VIEW_HOTEL_CARD_META_CLICKED',
  DAY_VIEW_META_REDIRECT: 'DAY_VIEW_META_REDIRECT',
  DAY_VIEW_FILTERS_USED: 'DAY_VIEW_FILTERS_USED',
  DAY_VIEW_SORT_USED: 'DAY_VIEW_SORT_USED',
  SEARCH_RESULT_SELECTED: 'SEARCH_RESULT_SELECTED',
  PRICES_LOADED: 'PRICES_LOADED',
  ROOM_INFO_MODAL_BUTTON_CLICKED: 'ROOM_INFO_MODAL_BUTTON_CLICKED',
  ROOM_INFO_MODAL_CLICKED: 'ROOM_INFO_MODAL_CLICKED',
  DIRECT_MODAL_CLICKED: 'DIRECT_MODAL_CLICKED',
  PRICE_BREAK_DOWN_MODAL_CLICKED: 'PRICE_BREAK_DOWN_MODAL_CLICKED',
  PRICE_DBOOK_SELECTED: 'PRICE_DBOOK_SELECTED',
  PRICE_META_SELECTED: 'PRICE_META_SELECTED',
  PRICE_SELECTED: 'PRICE_SELECTED',
  MOST_POPULAR_RATE_CARD_CLICKED: 'MOST_POPULAR_RATE_CARD_CLICKED',
  BEST_OFFER_RATE_CARD_CLICKED: 'BEST_OFFER_RATE_CARD_CLICKED',
  MAIN_CTA_BUTTON_CLICKED: 'MAIN_CTA_BUTTON_CLICKED',
  MOST_POPULAR_PRICES_MODAL_BUTTON_CLICKED:
    'MOST_POPULAR_PRICES_MODAL_BUTTON_CLICKED',
  RATE_BOOK_BUTTON_CLICKDED: 'RATE_BOOK_BUTTON_CLICKDED',
  SPECIFIC_RATE_BOOK_DBOOK_BUTTON_CLICKED:
    'SPECIFIC_RATE_BOOK_DBOOK_BUTTON_CLICKED',
  DBOOK_CLICK: 'DBOOK_CLICK',
  AVAILABLE_DEALS_CLICKED: 'AVAILABLE_DEALS_CLICKED',
  MOST_POPULAR_RATE_CARD_DBOOK_CLICKED: 'MOST_POPULAR_RATE_CARD_DBOOK_CLICKED',
  MOST_POPULAR_RATE_CARD_META_CLICKED: 'MOST_POPULAR_RATE_CARD_META_CLICKED',
  MOST_POPULAR_PRICES_MODAL_SELECT_ROOM_BUTTON_CLICKED:
    'MOST_POPULAR_PRICES_MODAL_SELECT_ROOM_BUTTON_CLICKED',
  BEST_OFFER_DBOOK_RATE_CARD_CLICKED: 'BEST_OFFER_DBOOK_RATE_CARD_CLICKED',
  BEST_OFFER_META_RATE_CARD_CLICKED: 'BEST_OFFER_META_RATE_CARD_CLICKED',
  MAIN_CTA_DBOOK_BUTTON_CLICKED: 'MAIN_CTA_DBOOK_BUTTON_CLICKED',
  MAIN_CTA_META_BUTTON_CLICKED: 'MAIN_CTA_META_BUTTON_CLICKED',
  MOST_POPULAR_PRICES_MODAL_GO_TO_SITE_BUTTON_CLICKED:
    'MOST_POPULAR_PRICES_MODAL_GO_TO_SITE_BUTTON_CLICKED',
  CHECKOUT_FORM_BOOK_BUTTON_CLICKED: 'CHECKOUT_FORM_BOOK_BUTTON_CLICKED',
  YOUR_DETAILS_NEXT_BUTTON_CLICKED: 'YOUR_DETAILS_NEXT_BUTTON_CLICKED',
  HOTELS_HOME_PAGE_LOAD: 'HOTELS_HOME_PAGE_LOAD',
  HOTELS_RESULTS_PAGE_LOAD: 'HOTELS_RESULTS_PAGE_LOAD',
  HOTELS_DBOOK_PAGE_LOAD: 'HOTELS_DBOOK_PAGE_LOAD',
  HOTELS_PRESALE_PAGE_LOAD: 'HOTELS_PRESALE_PAGE_LOAD',
  HOTELS_IN_CITY_PAGE_LOAD: 'HOTELS_IN_CITY_PAGE_LOAD',
  STAR_HOTELS_IN_CITY_PAGE_LOAD: 'STAR_HOTELS_IN_CITY_PAGE_LOAD',
  HOTELS_IN_NEIGHBOURHOOD_PAGE_LOAD: 'HOTELS_IN_NEIGHBOURHOOD_PAGE_LOAD',
  HOTELS_NEAR_PLACE_PAGE_LOAD: 'HOTELS_NEAR_PLACE_PAGE_LOAD',
  HOTELS_DETAILS_PAGE_LOAD: 'HOTELS_DETAILS_PAGE_LOAD',
  DAY_VIEW_HOTEL_RECOMMEND_CARD_CLICKED:
    'DAY_VIEW_HOTEL_RECOMMEND_CARD_CLICKED',
  DAY_VIEW_HOTEL_RECOMMEND_TAG_CLICKED: 'DAY_VIEW_HOTEL_RECOMMEND_TAG_CLICKED',
  DAY_VIEW_HOTEL_RECOMMEND_CLOSE_CLICKED:
    'DAY_VIEW_HOTEL_RECOMMEND_CLOSE_CLICKED',
  VIEWED_HOTEL_CITY_FILTER_CLICK: 'VIEWED_HOTEL_CITY_FILTER_CLICK',
  VIEWED_HOTEL_VIEW_ALL_CLICK: 'VIEWED_HOTEL_VIEW_ALL_CLICK',
  VIEWED_HOTEL_CLEAR_MODAL_CLICK: 'VIEWED_HOTEL_CLEAR_MODAL_CLICK',
  VIEWED_HOTEL_STAY_EDIT: 'VIEWED_HOTEL_STAY_EDIT',
  VIEWED_HOTEL_VIEW_ENTRANCE_CLICK: 'VIEWED_HOTEL_VIEW_ENTRANCE_CLICK',
  VIEWED_HOTEL_CLEAR_CLICK: 'VIEWED_HOTEL_CLEAR_CLICK',
  VIEWED_HOTEL_CLEAR_CANCEL_CLICK: 'VIEWED_HOTEL_CLEAR_CANCEL_CLICK',
  VIEWED_HOTEL_HIDE_CLICK: 'VIEWED_HOTEL_HIDE_CLICK',
  VIEWED_HOTEL_SHOW_CLICK: 'VIEWED_HOTEL_SHOW_CLICK',
  VIEWED_HOTELS_PAGE_LOAD: 'VIEWED_HOTELS_PAGE_LOAD',
  DETAIL_PAGE_NEARBY_MAP_CLICK: 'DETAIL_PAGE_NEARBY_MAP_CLICK',
  MWEB_NEW_DAY_VIEW_MAP_CLICK: 'MWEB_NEW_DAY_VIEW_MAP_CLICK',
  DAY_VIEW_PAGINATION_CLICK: 'DAY_VIEW_PAGINATION_CLICK',
  DAY_VIEW_MAP_PANEL_CLICK: 'DAY_VIEW_MAP_PANEL_CLICK',
  HOTEL_DETAIL_ADDRESS_CLICK: 'HOTEL_DETAIL_ADDRESS_CLICK',
  HOTEL_DETAIL_RECOMMEND_HOTEL_CHIP_CLICK:
    'HOTEL_DETAIL_RECOMMEND_HOTEL_CHIP_CLICK',
  DAY_VIEW_MAP_SEARCH_CITY_CHANGE: 'DAY_VIEW_MAP_SEARCH_CITY_CHANGE',
  SEARCH_SYNC_MAP_CHECKBOX_CLICK: 'SEARCH_SYNC_MAP_CHECKBOX_CLICK',
  DAY_VIEW_MAP_SEARCH_THIS_AREA_CLICK: 'DAY_VIEW_MAP_SEARCH_THIS_AREA_CLICK',
  FIND_THESE_GREAT_DEALS_RENDERED: 'FIND_THESE_GREAT_DEALS_RENDERED',
  FIND_THESE_GREAT_DEALS_CLICKED: 'FIND_THESE_GREAT_DEALS_CLICKED',
  DETAILS_CLOSE_POIS_CARD_CLICKED: 'DETAILS_CLOSE_POIS_CARD_CLICKED',
  DETAILS_RATES_FILTER_CLICK: 'DETAILS_RATES_FILTER_CLICK',
  AUTO_SUGGEST_POIS_RENDER: 'AUTO_SUGGEST_POIS_RENDER',
  AUTO_SUGGEST_POIS_CLICK: 'AUTO_SUGGEST_POIS_CLICK',
  AUTO_SUGGEST_RECENT_SEARCH_RENDER: 'AUTO_SUGGEST_RECENT_SEARCH_RENDER',
  VIEW_BUTTON_CLICKED: 'VIEW_BUTTON_CLICKED',
  BASE_ROOM_TYPE_FILTER_CLICKED: 'BASE_ROOM_TYPE_FILTER_CLICKED',
  SEARCH_RESULT_LOADED: 'SEARCH_RESULT_LOADED',
  BOOKING_PROCESS_STATE: 'BOOKING_PROCESS_STATE',
  BOOKING_PERFORMED: 'BOOKING_PERFORMED',
  MAP_ACTIVE_DAY_VIEW: 'MAP_ACTIVE_DAY_VIEW',
  MAP_MOVING_DAY_VIEW: 'MAP_MOVING_DAY_VIEW',
  POI_MENU_OPEN_DAY_VIEW: 'POI_MENU_OPEN_DAY_VIEW',
  POI_MENU_SELECT_DAY_VIEW: 'POI_MENU_SELECT_DAY_VIEW',
  POI_SEARCH_DAY_VIEW: 'POI_SEARCH_DAY_VIEW',
  VIEWED_HOTELS_DATA_LOADED: 'VIEWED_HOTELS_DATA_LOADED',
  PRICES_LOADED_META_ONLY: 'PRICES_LOADED_META_ONLY',
  PRICES_LOADED_DBOOK_ONLY: 'PRICES_LOADED_DBOOK_ONLY',
  PRICES_LOADED_HYBRID: 'PRICES_LOADED_HYBRID',
  HOTEL_PRICES_STATUS: 'HOTEL_PRICES_STATUS',
  MAP_HOTEL_MARKER_CLICK: 'MAP_HOTEL_MARKER_CLICK',
  UNIFIED_PAYMENT_INTEGRATION: 'UNIFIED_PAYMENT_INTEGRATION',
  LOGIN_DEAL_PROCESS: 'LOGIN_DEAL_PROCESS',
  PRE_CHECK_STATE: 'PRE_CHECK_STATE',
};

export const BASE_ROOM_TYPE = {
  UNSET_ROOM_TYPE: 'UNSET_ROOM_TYPE',
  SINGLE_ROOM: 'SINGLE_ROOM',
  DOUBLE_ROOM: 'DOUBLE_ROOM',
  TWIN_ROOM: 'TWIN_ROOM',
  TRIPLE_ROOM: 'TRIPLE_ROOM',
  SHARED_ROOM: 'SHARED_ROOM',
  FAMILY_ROOM: 'FAMILY_ROOM',
  SUITE_ROOM: 'SUITE_ROOM',
};

export const RATE_LAYOUT_TYPE = {
  UNSET_RATE_LAYOUT_TYPE: 'UNSET_RATE_LAYOUT_TYPE',
  ONLY_META: 'ONLY_META',
  ONLY_DBOOK: 'ONLY_DBOOK',
  META_AND_DBOOK: 'META_AND_DBOOK',
};

export const CLICK_AREA = {
  UNSET_CLICK_AREA: 'UNSET_CLICK_AREA',
  CITY_FILTER: 'CITY_FILTER',
  CITY_FILTER_IN_FULL_PAGE: 'CITY_FILTER_IN_FULL_PAGE',
  DETAIL_VIEW_DETAILS: 'DETAIL_VIEW_DETAILS',
  DETAIL_VIEW_MORE: 'DETAIL_VIEW_MORE',
  DETAIL_VIEW_LESS: 'DETAIL_VIEW_LESS',
  DETAIL_RATE_CARD_CTA_BOOK: 'DETAIL_RATE_CARD_CTA_BOOK',
  DETAIL_RATE_DETAILS_MODAL_BOOK: 'DETAIL_RATE_DETAILS_MODAL_BOOK',
  DETAIL_RATE_PRICE_MODAL_BOOK: 'DETAIL_RATE_PRICE_MODAL_BOOK',
};

export const PRICE_TYPE = {
  UNSET_PRICE_TYPE: 'UNSET_PRICE_TYPE',
  DBOOK: 'DBOOK',
  META: 'META',
  FSS: 'FSS',
};

export const FILTER_TYPE = {
  UNSET_FILTER_TYPE: 'UNSET_FILTER_TYPE',
  CONFIDENT_TYPES: 'CONFIDENT_TYPE_FILTER',
  PRICE: 'PRICE_FILTER',
  STARS: 'STARS_FILTER',
  PROPERTY_TYPE: 'PROPERTY_TYPE_FILTER',
  CANCELLATION: 'CANCELLATION_FILTER',
  MEAL_PLAN: 'MEAL_PLAN_FILTER',
  RATING: 'RATING_FILTER',
  DISCOUNT_TYPES: 'DISCOUNT_TYPE_FILTER',
  AMENITIES: 'AMENITIES_FILTER',
  GUEST_TYPE: 'GUEST_TYPE_FILTER',
  CHAIN: 'CHAIN_FILTER',
  DISTRICT: 'DISTRICT_FILTER',
};

export const SORTING_COLUMNS = {
  UNSET_SORTING_COLUMNS: 'UNSET_SORTING_COLUMNS',
  RELEVANCE: 'RELEVANCE',
  PRICE: 'PRICE',
  DISTANCE: 'DISTANCE',
  NAME: 'NAME',
  RATING: 'RATING',
  CATEGORY: 'CATEGORY',
  POPULARITY: 'POPULARITY',
  STARS: 'STARS',
  HOTEL_RATING: 'HOTEL_RATING',
};

export const FILTER_AREA_TYPE = {
  UNSET_FILTER_AREA_TYPE: 'UNSET_FILTER_AREA_TYPE',
  FILTER_PANEL: 'FILTER_PANEL',
  QUICK_FILTER: 'QUICK_FILTER',
  FILTER_REMOVE: 'FILTER_REMOVE',
};

export const REGION = {
  UNSET_REGION: 'UNSET_DEPLOY_COLOUR',
  EU_WEST_1: 'EU_WEST_1',
  EU_CENTRAL_1: 'EU_CENTRAL_1',
  AP_SOUTHEAST_1: 'AP_SOUTHEAST_1',
  AP_NORTHEAST_1: 'AP_NORTHEAST_1',
};

export const DEVICE = {
  UNSET_DEVICE: 'UNSET_DEVICE',
  TABLET: 'TABLET_WEB',
  DESKTOP: 'DESKTOP_WEB',
  MOBILE: 'MOBILE_WEB',
};

export const CLICK_TYPE = {
  UNSET_POI_TYPE: 'UNSET_POI_TYPE',
  MAP_VIEW: 'MAP_VIEW',
  MAP_VIEW_MARKER: 'MAP_VIEW_MARKER',
  GOOD_POINT: 'GOOD_POINT',
  TRANSPORTATION: 'TRANSPORTATION',
  AIRPORT: 'AIRPORT',
  CITY_CENTER: 'CITY_CENTRE',
  NEAREST_POINT: 'NEAREST_POINT',
  NEAREST_TRANSPORTATION: 'NEAREST_TRANSPORTATION',
  NEAREST_AIRPORT: 'NEAREST_AIRPORT',
  ADDRESS: 'ADDRESS',
  NEARBY_HOTEL: 'NEARBY_HOTEL',
  LANDMARK: 'LANDMARK',
  ENTERTAINMENT: 'ENTERTAINMENT',
  SHOPPING: 'SHOPPING',
  EDUCATION: 'EDUCATION',
};

export const ENTITY_TYPE = {
  UNSET_ENTITY_TYPE: 'UNSET_ENTITY_TYPE',
  HOTEL: 'SPECIFIC_HOTEL',
  CITY: 'CITY',
  DISTRICT: 'DISTRICT',
  NATION: 'NATION',
  TERRITORY: 'TERRITORY',
  ISLAND: 'ISLAND',
  OCEAN: 'OCEAN',
  REGION: 'REGION',
  FIRSTLEVELNATIONADMINISTRATIVEDIVISION: 'FIRST_ADMINISTRATIVE_DIVISION',
  SECONDLEVELNATIONADMINISTRATIVEDIVISION: 'SECOND_ADMINISTRATIVE_DIVISION',
  THIRDLEVELNATIONADMINISTRATIVEDIVISION: 'THIRD_ADMINISTRATIVE_DIVISION',
  AMUSEMENTPARK: 'AMUSEMENT_PARK',
  BEACH: 'BEACH',
  BUSSTATION: 'BUS_STATION',
  CAMPGROUND: 'CAMPGROUND',
  CARPARKINDOORS: 'CAR_PARK_INDOORS',
  CARPARKOUTDOORS: 'CAR_PARK_OUTDOORS',
  CARPARK: 'CAR_PARK',
  CARRENTAL: 'CAR_RENTAL',
  CASINOBUILDING: 'CASINO_BUILDING',
  CINEMAHALL: 'CINEMA_HALL',
  CONCERTHALL: 'CONCERT_HALL',
  CONVENTIONHALL: 'CONVENTION_HALL',
  COURTHOUSE: 'COURTHOUSE',
  CULTURALCENTERBUILDING: 'CULTURAL_CENTER_BUILDING',
  EMBASSYBUILDING: 'EMBASSY_BUILDING',
  EXHIBITIONCENTERBUILDING: 'EXHIBITION_CENTER_BUILDING',
  FRONTIERCROSSING: 'FRONTIER_CROSSING',
  GOLFCOURSE: 'GOLF_COURSE',
  GOVERNMENTBUILDING: 'GOVERNMENT_BUILDING',
  HARBOR: 'HARBOR',
  HISTORICSITE: 'HISTORIC_SITE',
  HOSPITAL: 'HOSPITAL',
  LEISURECENTERBUILDING: 'LEISURE_CENTER_BUILDING',
  LIBRARY: 'LIBRARY',
  MONUMENTLANDMARK: 'MONUMENT_LANDMARK',
  MOUNTAINPASS: 'MOUNTAIN_PASS',
  MOUNTAINPEAK: 'MOUNTAIN_PEAK',
  MUSEUM: 'MUSEUM',
  OPERAHALL: 'OPERA_HALL',
  PARK: 'PARK',
  RELIGIOUSBUILDING: 'RELIGIOUS_BUILDING',
  SHOPPINGMALL: 'SHOPPING_MALL',
  SKISTATION: 'SKI_STATION',
  SPORTSFACILITY: 'SPORTS_FACILITY',
  STADIUM: 'STADIUM',
  THEATREHALL: 'THEATRE_HALL',
  TOURISTATTRACTION: 'TOURIST_ATTRACTION',
  TOURISTINFORMATIONOFFICE: 'TOURIST_INFORMATION_OFFICE',
  TRAINSTATION: 'TRAIN_STATION',
  TRANSITWAYPLAZA: 'TRANSITWAY_PLAZA',
  METROSTATION: 'METRO_STATION',
  UNIVERSITYBUILDING: 'UNIVERSITY_BUILDING',
  ZOO: 'ZOO',
  ARTCENTER: 'ART_CENTER',
  ARTGALLERY: 'ART_GALLERY',
  BRIDGE: 'BRIDGE',
  CANYON: 'CANYON',
  CASTLE: 'CASTLE',
  CAVE: 'CAVE',
  DESERT: 'DESERT',
  FARM: 'FARM',
  FERRISWHEEL: 'FERRIS_WHEEL',
  FILMSTUDIO: 'FILM_STUDIO',
  FOREST: 'FOREST',
  FOUNTAIN: 'FOUNTAIN',
  GARDEN: 'GARDEN',
  GEOPARK: 'GEOPARK',
  GRASSLAND: 'GRASSLAND',
  HOTSPRING: 'HOT_SPRING',
  LAKE: 'LAKE',
  MODERNARCHITECTURE: 'MODERN_ARCHITECTURE',
  NATURERESERVE: 'NATURE_RESERVE',
  RIVER: 'RIVER',
  SCHOOL: 'SCHOOL',
  SPRING: 'SPRING',
  VOLCANO: 'VOLCANO',
  WATERFALL: 'WATERFALL',
  WETLAND: 'WETLAND',
  WORKSHOP: 'WORKSHOP',
};

export const FILTER_VALUE_TYPE = {
  stars: {
    0: 'is_no_stars_selected',
    1: 'is_one_stars_selected',
    2: 'is_two_stars_selected',
    3: 'is_three_stars_selected',
    4: 'is_four_stars_selected',
    5: 'is_five_stars_selected',
  },
  cancellation: {
    free_cancellation: 'is_free_cancellation_selected',
    refundable: 'is_refundable_selected',
    non_refundable: 'is_non_refundable_selected',
  },
  meal_plan: {
    breakfast_included: 'is_breakfast_included_selected',
    breakfast_not_included: 'is_breakfast_not_included_selected',
    RoomRates_label_mealsNotIncluded: 'is_meals_not_included_selected',
    SomeMealsInc: 'is_some_meals_included_selected',
  },
  rating: {
    5: 'is_with_honours_selected',
    4.5: 'is_excellent_selected',
    4: 'is_very_good_selected',
    3.5: 'is_good_selected',
    3: 'is_satisfactory_selected',
  },
  property_type: {
    Hotel: 'is_hotel_selected',
    Apartment: 'is_apartment_selected',
    BedAndBreakfast: 'is_bed_and_breakfast_selected',
    GuestHouse: 'is_guest_house_selected',
    Hostel: 'is_hostel_selected',
    HolidayRentals: 'is_holiday_rentals_selected',
    ApartHotel: 'is_apart_hotel_selected',
    Resort: 'is_resort_selected',
    TownHouse: 'is_town_house_selected',
    ServicedApartment: 'is_serviced_apartment_selected',
    UniqueStays: 'is_unique_stays_selected',
  },
  discount_types: {
    cug: 'is_only_show_discounts_selected',
    coupon: 'is_show_coupon_offers_selected',
    direct_discount: 'is_show_these_great_deals_selected',
  },
};

export const FILTER_OPTION_TYPE = {
  discount_types: 'deals',
};

export const RATES_FILTER_TYPE = {
  cancellation: 'is_free_cancellation_selected',
  meal_plan: 'is_breakfast_included_selected',
  pay_type: 'is_pay_on_arrival_selected',
  dBook_rate: 'is_dbook_selected',
};

export const AUTO_SUGGEST_POI_LIST = [
  'poi_id_one',
  'poi_id_two',
  'poi_id_three',
  'poi_id_four',
  'poi_id_five',
];

export const SORTING_CLICK_AREA_TYPE = {
  UNSET_SORTING_CLICK_AREA: 'UNSET_SORTING_CLICK_AREA',
  HEADER_BAR: 'HEADER_BAR',
  AMONG_LIST: 'AMONG_LIST',
  MAP_CHIP_BAR: 'MAP_CHIP_BAR',
  MAP_MARKER: 'MAP_MARKER',
};

export const RESULT_TYPE = {
  UNSET_RESULT_TYPE: 'UNSET_RESULT_TYPE',
  AUTO_SUGGEST: 'AUTO_SUGGEST',
  POPULAR: 'POPULAR',
  RECENT: 'RECENT',
  CURRENT_LOCATION: 'CURRENT_LOCATION',
};

export const CUG_TYPE = {
  UNSET_CLOSED_USER_GROUP: 'UNSET_CLOSED_USER_GROUP',
  UNSET_CUG_TYPE: 'UNSET_CUG_TYPE',
  MOBILE: 'MOBILE',
  LOGGED_IN: 'LOGGED_IN',
  GENERAL: 'GENERAL',
  FLIGHT_BOOKED: 'FLIGHT_BOOKED',
  FLIGHT_CUSTOMER: 'FLIGHT_CUSTOMER',
  HOT_DEAL: 'HOT_DEAL',
  HOTEL_BOOKED: 'HOTEL_BOOKED',
  HOTEL_CUSTOMER: 'HOTEL_CUSTOMER',
  CAR_BOOKED: 'CAR_BOOKED',
  CAR_CUSTOMER: 'CAR_CUSTOMER',
  LOYALTY: 'LOYALTY',
  FLIGHT_SEARCH: 'FLIGHT_SEARCH',
  HOTEL_SEARCH: 'HOTEL_SEARCH',
  CAR_SEARCH: 'CAR_SEARCH',
};

export const MESSAGE_STATE = {
  UNSET_MESSAGE_STATE: 'UNSET_MESSAGE_STATE',
  INVALID_DATA,
  INVALID_BRAINTREE_TOKEN,
  CREATE_FAIL,
  UNEXPECTED_ERROR,
  PAYMENT_TOKEN_FAILED,
  PRICE_CHANGED: 'PRICE_CHANGED',
  RATE_AVAILABLE: 'RATE_AVAILABLE',
  ...MessageStates,
};

export const BOOKING_STATUS_MAPPING = {
  '3DSECURE': 'BOOKING_THREE_DS',
  INVALID: 'BOOKING_INVALID',
  PRICE_CHANGE: 'BOOKING_PRICE_CHANGE',
  PENDING: 'BOOKING_PENDING',
  '3DS_PENDING': 'BOOKING_THREE_DS_PENDING',
  FAILED: 'BOOKING_FAILED',
  CONFIRMED: 'BOOKING_CONFIRMED',
  ACCEPTED: 'BOOKING_ACCEPTED',
  UNCONFIRMED: 'BOOKING_UNCONFIRMED',
};

export const BOOKING_PROCESS_STATE = {
  UNSET_BOOKING_PROCESS_STATE: 'UNSET_BOOKING_PROCESS_STATE',
  INITIAL_PRE_CHECK: 'INITIAL_PRE_CHECK',
  CURRENT_PRE_CHECK: 'CURRENT_PRE_CHECK',
  FAILED_FRONTEND: 'FAILED_FRONTEND',
  INVALID: 'INVALID',
  ...DbookFunnelStates,
};

export const BOOKING_STATUS = {
  UNSET_BOOKING_STATUS: 'UNSET_BOOKING_STATUS',
  BOOKING_THREE_DS: 'BOOKING_THREE_DS',
  BOOKING_INVALID: 'BOOKING_INVALID',
  BOOKING_PRICE_CHANGE: 'BOOKING_PRICE_CHANGE',
  BOOKING_PENDING: 'BOOKING_PENDING',
  BOOKING_THREE_DS_PENDING: 'BOOKING_THREE_DS_PENDING',
  BOOKING_FAILED: 'BOOKING_FAILED',
  BOOKING_CONFIRMED: 'BOOKING_CONFIRMED',
  BOOKING_ACCEPTED: 'BOOKING_ACCEPTED',
  BOOKING_UNCONFIRMED: 'BOOKING_UNCONFIRMED',
};

export const HOTEL_CARD_AREA = {
  UNSET_HOTEL_CARD_AREA: 'UNSET_HOTEL_CARD_AREA',
  MAP_PANEL: 'MAP_PANEL',
  RECOMMENDED: 'RECOMMENDED',
  VIEWED: 'VIEWED',
};

export const DAY_VIEW_MAP_CLICK_AREA = {
  TOP_BANNER: 'TOP_BANNER',
  STICKY_BUTTON: 'STICKY_BUTTON',
};

export const POI_MAP_CLICK_AREA = {
  POI_MENU: 'POI_MENU',
  POI_CHIPS: 'POI_CHIPS',
};

export const PRICE_CHANGED_TYPE = {
  UNSET_CHANGED_TYPE: 'UNSET_CHANGED_TYPE',
  CHEAPER: 'CHEAPER',
  EXPENSIVE: 'EXPENSIVE',
};

export const PRICE_CHANGED_TYPE_KEYS = {
  decrease: 'CHEAPER',
  increase: 'EXPENSIVE',
};

export const POLICY_CHANGED_TYPE = {
  UNSET_POLICY_CHANGED_TYPE: 'UNSET_POLICY_CHANGED_TYPE',
  BAD: 'BAD',
  GOOD: 'GOOD',
  NEUTRAL: 'NEUTRAL',
};

export const UNIFIED_INTEGRATION_STEP_TYPE = {
  UNSET_STEP_TYPE: 'UNSET_STEP_TYPE',
  INIT: 'INIT',
  UPDATE: 'UPDATE',
};

export const ACTION_STATUS_YPE = {
  UNSET_STATUS_TYPE: 'UNSET_STATUS_TYPE',
  SUCCESSFUL: 'SUCCESSFUL',
  ERROR: 'ERROR',
};

export const PARTNER_ID = {
  UNSET_PARTNER_ID: 'UNSET_PARTNER_ID',
  D_CT: 'D_CT',
  D_SG: 'D_SG',
};

export const USER_ACCOUNT_TYPES = {
  UNSET_USER_ACCOUNT_TYPES: 'UNSET_USER_ACCOUNT_TYPES',
  OPTED_IN_USER: 'OPTED_IN_USER',
  NEW_USER: 'NEW_USER',
};

export const LOGIN_DEAL_PROCESS_STATE = {
  UNSET_LOGIN_DEAL_PROCESS_STATE: 'UNSET_LOGIN_DEAL_PROCESS_STATE',
  LOGIN_FIELD_LOAD: 'LOGIN_FIELD_LOAD',
  VERIFY_CODE_CLICK: 'VERIFY_CODE_CLICK',
  ACCOUNT_REMINDER_LOAD: 'ACCOUNT_REMINDER_LOAD',
};
