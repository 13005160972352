import type { ReactNode } from 'react';

import BpkLargeCalendarIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import BpkLabel from '@skyscanner/backpack-web/bpk-component-label';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { Maybe } from '../../types';

import STYLES from './FormField.scss';

type Props = {
  fieldId: string;
  label?: string;
  children: ReactNode;
  className?: Maybe<string>;
  lightLabel?: boolean;
  newHeaderDateRange?: boolean;
};

const cls = cssModules(STYLES);

const FormField = ({
  children,
  className = undefined,
  fieldId,
  label = undefined,
  lightLabel = false,
  newHeaderDateRange = false,
}: Props) => {
  const classNames = [cls('FormField')];
  if (className) {
    classNames.push(className);
  }

  if (!label) {
    classNames.push(cls('FormField__withoutLabel'));
  }

  return (
    <div className={classNames.join(' ')}>
      <BpkLabel
        htmlFor={fieldId}
        className={cls(
          lightLabel && 'FormField__label--light',
          newHeaderDateRange && 'FormField__label--newHeaderDateRange',
        )}
        title={label}
      >
        {newHeaderDateRange && <BpkLargeCalendarIcon />}
        {label}
      </BpkLabel>
      {children}
    </div>
  );
};

export default FormField;
