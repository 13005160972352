import { Component } from 'react';
import type { ReactNode } from 'react';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import BpkDialog from '@skyscanner/backpack-web/bpk-component-dialog';
import BpkSmallInfoCircleIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/information-circle';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import {
  Paragraph,
  ParagraphHeading3,
} from '../../../../../../../components/HotelsCommon/Text';
import { withI18n } from '../../../../skyscanner-application/i18n';
import { getApplicationElement } from '../../../Common/ApplicationElements';

import type { I18nShape } from '../../../../skyscanner-application/i18n';

import STYLES from './PriceInfoDialog.scss';

type Props = {
  i18n: I18nShape;
  dismissible?: boolean;
  headerIcon?: ReactNode;
};
type State = {
  isOpen: boolean;
};

const defaultProps = {
  dismissible: true,
  headerIcon: null,
};
const cls = cssModules(STYLES);

class PriceInfoDialog extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onOpen = () => {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
      });
    }
  };

  onClose = () => {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    const { translate } = this.props.i18n;
    return (
      <div>
        <BpkSmallInfoCircleIcon
          onClick={this.onOpen}
          className={cls('DialogContainer__outerIcon')}
        />
        <BpkDialog
          id="priceInfo-popover-modal"
          ariaLabel="price info"
          dialogRef={() => null}
          className={cls('DialogContainer')}
          isOpen={this.state.isOpen}
          onClose={this.onClose}
          getApplicationElement={getApplicationElement}
          headerIcon={this.props.headerIcon}
          dismissible={this.props.dismissible}
        >
          <div className={cls('ScrollableDateRangeSelector__info--title')}>
            <ParagraphHeading3>
              {translate('label_calendar_price_information_title')}
            </ParagraphHeading3>
          </div>
          <div className={cls('ScrollableDateRangeSelector__info--content')}>
            <Paragraph>
              {translate('label_calendar_price_information_content')}
            </Paragraph>
          </div>
          <BpkButton
            className={cls('DialogContainer__button')}
            onClick={this.onClose}
          >
            {translate('Reviews_GotIt')}
          </BpkButton>
        </BpkDialog>
      </div>
    );
  }
}

export default withI18n(PriceInfoDialog);
