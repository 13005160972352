import PropTypes from 'prop-types';

import BpkSmallAdultIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/adult';
import BpkSmallCalendarIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/calendar';

import { Paragraph } from '../../../../../../components/HotelsCommon/Text';
import { alignIconTextBase } from '../../../components/Common/Alignment';
import { StayShape } from '../../../components/shapes';
import { DATE_FORMAT, withI18n } from '../../../skyscanner-application/i18n';
import { I18nShape } from '../../../skyscanner-application/i18n/i18n-shape';

import STYLES from './SearchBarHotelStay.module.scss';

const SearchBarHotelStay = ({ i18n, preferHorizontal = undefined, stay }) => {
  const { formatDate, translate, translatePlural } = i18n;
  const { checkIn, checkOut, childrenAges, numberOfAdults, numberOfRooms } =
    stay;
  const arrayOfChildrenLen = childrenAges && childrenAges.length;

  const AlignedSmallCalendarIconBaseText =
    alignIconTextBase(BpkSmallCalendarIcon);
  const AlignedSmallAdultIconBaseText = alignIconTextBase(BpkSmallAdultIcon);

  const stayDatesLabel = translate('HotelStay_label_stayDates', {
    start_date: formatDate(checkIn, DATE_FORMAT.MEDIUM),
    end_date: formatDate(checkOut, DATE_FORMAT.MEDIUM),
  });

  const guestsLabel = translatePlural(
    'HotelStay_label_adults_',
    numberOfAdults,
    '0',
    null,
    9,
    'X',
  );

  const roomsLabel = translatePlural(
    'SelectionSummary_label_rooms',
    numberOfRooms,
    'number',
  );

  const childrenLabel =
    arrayOfChildrenLen > 0 &&
    translatePlural('HotelStay_label_children_', arrayOfChildrenLen, 'number');

  const classNames = [STYLES.SearchBarHotelStay];
  if (preferHorizontal) {
    classNames.push(STYLES['SearchBarHotelStay--horizontal']);
  }

  return (
    <div className={classNames.join(' ')}>
      {checkIn && checkOut && (
        <Paragraph data-test-id="search-bar-stay">
          <AlignedSmallCalendarIconBaseText
            className={STYLES.SearchBarHotelStay__icon}
          />
          {stayDatesLabel}
        </Paragraph>
      )}
      {numberOfAdults && numberOfRooms && (
        <Paragraph>
          <AlignedSmallAdultIconBaseText
            className={STYLES.SearchBarHotelStay__icon}
          />
          {arrayOfChildrenLen > 0 ? (
            <span>
              {guestsLabel} - {childrenLabel} - {roomsLabel}
            </span>
          ) : (
            <span>
              {guestsLabel} - {roomsLabel}
            </span>
          )}
        </Paragraph>
      )}
    </div>
  );
};

SearchBarHotelStay.propTypes = {
  i18n: I18nShape.isRequired,
  stay: StayShape.isRequired,
  preferHorizontal: PropTypes.bool,
};

export default withI18n(SearchBarHotelStay);
