import PropTypes from 'prop-types';
import { Component } from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { parseStayProp } from '../../../../../../components/HotelsCommon/utils';
import SearchControls from '../../../components/SearchControls';
import { StayShape, DestinationShape } from '../../../components/shapes';
import { withI18n } from '../../../skyscanner-application/i18n';
import { I18nShape } from '../../../skyscanner-application/i18n/i18n-shape';

import SearchBarHotelStay from './SearchBarHotelStay';

import STYLES from './SearchBar.scss';

const cls = cssModules(STYLES);

class SearchBar extends Component {
  constructor(props) {
    super(props);

    const { arrangeInline } = props;

    this.state = {
      expanded: arrangeInline ? true : props.expanded,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isMobile &&
      !prevState.expanded &&
      this.props.isMobile !== prevProps.isMobile
    ) {
      this.setState({ expanded: true });
    }
  }

  onSearchControlsSubmit = (params) => {
    const { onSearchControlsSubmit } = this.props;
    onSearchControlsSubmit(params);
  };

  destinationLabel = () => {
    const {
      i18n: { translate },
    } = this.props;

    return translate('SearchControls_label_whereDoYouWantToStay');
  };

  render() {
    const { arrangeInline, className, destination, isMobile, stay } =
      this.props;

    const parsedStay = parseStayProp(stay);
    const { entity: destinationName } = destination || {};
    const classNames = [cls('SearchBar'), className];

    return (
      <div className={classNames.join(className ? ' ' : '')}>
        {!arrangeInline && (
          <div
            role="button"
            className={cls('SearchBar__staticInfo')}
            tabIndex="0"
            data-test-id="search-bar"
          >
            <div className={cls('SearchBar__info')}>
              <div
                className={cls('SearchBar__destination')}
                data-test-id="destination"
              >
                {destinationName}
              </div>
              <SearchBarHotelStay stay={parsedStay} preferHorizontal />
            </div>
          </div>
        )}
        <div
          className={cls(
            'SearchBar__searchControls',
            arrangeInline && 'SearchBar__searchControlsExpand',
          )}
        >
          <SearchControls
            stay={parsedStay}
            destination={destination}
            arrangeInline={arrangeInline}
            showPreference={!arrangeInline}
            isMobile={isMobile}
            onSubmit={this.onSearchControlsSubmit}
            destinationLabel={this.destinationLabel()}
          />
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  i18n: I18nShape.isRequired,
  onSearchControlsSubmit: PropTypes.func.isRequired,
  destination: DestinationShape,
  stay: StayShape.isRequired,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  arrangeInline: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

SearchBar.defaultProps = {
  className: null,
  destination: null,
  expanded: false,
  arrangeInline: false,
};

export default withI18n(SearchBar);
