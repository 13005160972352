import { Component } from 'react';

import { BpkScrollableCalendarDate } from '@skyscanner/backpack-web/bpk-component-scrollable-calendar';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { ParagraphXs } from '../../../../../components/HotelsCommon/Text';
import { withI18n, DATE_FORMAT } from '../../skyscanner-application/i18n';
import { withPriceData } from '../SearchControls/PriceDataProvider';
import transformPrice from '../SearchControls/transformPrice';

import type { I18nShape } from '../../skyscanner-application/i18n';
import type { CalendarPriceDataShape, Maybe } from '../types';

import STYLES from './CalendarDate.scss';

type Props = {
  i18n: I18nShape;
  date: Date;
  minDate: Date;
  maxDate: Date;
  startDate?: Date | null;
  endDate?: Date | null;
  isOutside: boolean;
  priceData: Maybe<CalendarPriceDataShape>;
};

const cls = cssModules(STYLES);

const priceTag = (
  direction: Maybe<string>,
  i18n: I18nShape,
  tagPrice: Maybe<number>,
) => transformPrice(i18n, tagPrice).get(direction || 'DEFAULT');

const defaultProps = {
  startDate: null,
  endDate: null,
};

class CalendarDate extends Component<Props> {
  static defaultProps = defaultProps;

  getPriceData = () => {
    const { date, i18n, priceData } = this.props;

    if (!priceData) {
      return {};
    }

    const price =
      priceData[i18n.formatDate(date, DATE_FORMAT.NON_LOCALISED_SHORT)];
    if (!price) {
      return {};
    }
    const { direction, median } = price;
    return { direction, ...priceTag(direction, i18n, median) };
  };

  render() {
    const {
      date,
      endDate,
      i18n,
      maxDate,
      minDate,
      priceData,
      startDate,
      ...rest
    } = this.props;
    const { isOutside } = rest;
    // @ts-ignore
    const { direction = '', price = '' } = this.getPriceData();

    return (
      <div
        className={cls(
          !isOutside && 'CalendarDate__dateBg',
          isOutside && price && 'CalendarDate__dateBg--outside',
        )}
      >
        <BpkScrollableCalendarDate date={date} {...rest} />

        {!isOutside && price && (
          <div
            className={cls(
              'CalendarDate__pill',
              direction && direction === 'CHEAP' && 'CalendarDate__pill--cheap',
            )}
          >
            <ParagraphXs>{!isOutside ? price : ''}</ParagraphXs>
          </div>
        )}
      </div>
    );
  }
}

export default withI18n(withPriceData(CalendarDate));
