import type { MouseEventHandler } from 'react';

import BpkInput, {
  INPUT_TYPES,
} from '@skyscanner/backpack-web/bpk-component-input';

import { HASH_LOCATION_MODAL } from '../../../services/routes';
import { withI18n } from '../../../skyscanner-application/i18n';
import BpkBreakpoint, { BREAKPOINTS } from '../../Common/BpkBreakpoint';
import RouteLink from '../../RouteLink';
import FormField from '../FormField';

import DestinationSelector from './DestinationSelector';
import DestinationSelectorModal from './DestinationSelectorModal';

import type { I18nShape } from '../../../skyscanner-application/i18n';
import type {
  AutosuggestSuggestionOrPopularDestinationShape,
  Maybe,
} from '../../types';
import type { DestinationShape } from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18nShape;
  className?: string;
  inputClassName?: string;
  destinationLabel: string;
  destination: DestinationShape;
  suggestions: AutosuggestSuggestionOrPopularDestinationShape;
  onChangeInputValue?: Maybe<Function>;
  onSuggestionsClearRequested: Function;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onChangeOpenCheckinSelector?: Maybe<Function>;
  lightLabel?: boolean;
  arrangeInline?: boolean;
  valid?: Maybe<boolean>;
};

const DESTINATION_INPUT_ID = 'destination-modal-input';

const DestinationSelectorContainer = ({
  arrangeInline = false,
  className = undefined,
  destination,
  destinationLabel,
  i18n,
  inputClassName = undefined,
  lightLabel = false,
  onChangeInputValue = undefined,
  onChangeOpenCheckinSelector = undefined,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  suggestions,
  valid = false,
}: Props) => (
  <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
    {(isMobile: boolean) =>
      isMobile ? (
        <>
          <RouteLink
            href={HASH_LOCATION_MODAL}
            onClick={() => {
              window.name = 'DVSearchModalIsOpen';
            }}
            render={(onClickLink: MouseEventHandler<HTMLDivElement>) => (
              <FormField
                fieldId={DESTINATION_INPUT_ID}
                label={destinationLabel}
                lightLabel
              >
                <BpkInput
                  id={DESTINATION_INPUT_ID}
                  name={DESTINATION_INPUT_ID}
                  className={inputClassName}
                  type={INPUT_TYPES.text}
                  value={destination && destination.entity}
                  placeholder={i18n.translate(
                    'SearchControls_label_Destination_placeholder',
                  )}
                  onClick={onClickLink}
                  valid={valid}
                  readOnly
                />
              </FormField>
            )}
          />
          <DestinationSelectorModal
            className={className}
            destinationLabel={destinationLabel}
            destination={destination}
            lightLabel={lightLabel}
            arrangeInline={arrangeInline}
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            suggestions={suggestions}
            onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
            cancelLabel={i18n.translate('Button_cancel')}
            onChangeInputValue={onChangeInputValue}
            valid={valid}
          />
        </>
      ) : (
        <DestinationSelector
          className={className}
          destinationLabel={destinationLabel}
          destination={destination}
          lightLabel={lightLabel}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          suggestions={suggestions}
          arrangeInline={arrangeInline}
          onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
          onChangeInputValue={onChangeInputValue}
          valid={valid}
        />
      )
    }
  </BpkBreakpoint>
);

export default withI18n(DestinationSelectorContainer);
