import type { ComponentType } from 'react';

import STYLES from './StackingContextReset.module.scss';

const withStackingContextReset =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: P) =>
    (
      <div className={STYLES.StackingContextReset}>
        <WrappedComponent {...props} />
      </div>
    );

export default withStackingContextReset;
