import { Component } from 'react';
import type { MouseEvent } from 'react';

import isEqual from 'lodash/isEqual';
import { LocalStorage } from 'saddlebag-localstorage';

import BpkAutosuggest, {
  BpkAutosuggestSuggestion,
} from '@skyscanner/backpack-web/bpk-component-autosuggest';
import { CLEAR_BUTTON_MODES } from '@skyscanner/backpack-web/bpk-component-input';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import logger from '../../../services/logger';
import { withConfiguration } from '../../../skyscanner-application/configuration';
import { withElementEventTracker } from '../../../skyscanner-application/element-events';
import { withI18n } from '../../../skyscanner-application/i18n';
import {
  buildControlsActivatedMessage,
  buildResultSelectedMessage,
  buildControlsDismissedMessage,
} from '../../../skyscanner-application/minievents/autosuggest';
import {
  ACTION_TYPE,
  RESULT_TYPE,
} from '../../../skyscanner-application/minievents/constants';
import { buildAdditionalInfoAutoSuggestPoiClick } from '../../../skyscanner-application/minievents/hotels-action';
import { AUTOSUGGEST_EVENTS } from '../../../skyscanner-application/newrelic-events/autosuggest-events';
import FormField from '../FormField';
import {
  getSubheadingValue,
  getSuggestionIcon,
  getPuredItem,
  pureSuggestValue,
  getSuggestionReplacedValue,
} from '../searchControlHelper';

import PlaceHolder from './PlaceHolder';
import RecentSearchAndPopulars from './RecentSearchAndPopulars';
import RecentSearchAndPopularsMobile from './RecentSearchAndPopularsMobile';

import type { ElementEventTrackerReturn } from '../../../skyscanner-application/element-events';
import type { I18nShape } from '../../../skyscanner-application/i18n';
import type { ConfigurationShape } from '../../../skyscanner-application/types';
import type {
  AutosuggestPoiShape,
  AutosuggestSuggestionOrPopularDestinationShape,
  AutosuggestSuggestionShape,
  Maybe,
  PopularDestinationItemShape,
  PopularDestinationShape,
} from '../../types';
import type { DestinationShape } from 'common-types/types/hotels-components/types';

import STYLES from './DestinationSelector.scss';

const cls = cssModules(STYLES);

const renderSuggestion = (suggestion: AutosuggestSuggestionShape) => (
  <BpkAutosuggestSuggestion
    value={getSuggestionReplacedValue(suggestion)}
    subHeading={getSubheadingValue(suggestion)}
    icon={getSuggestionIcon(suggestion)}
    data-test-id="autosuggest-suggestion"
    tertiaryLabel={suggestion.label}
  />
);

const buildDestinationState = (props: Props) => {
  const { entity: destinationName } = props.destination || {};

  return {
    value: destinationName || '',
  };
};

const hasSuggestions = (
  suggestions: AutosuggestSuggestionOrPopularDestinationShape,
) => suggestions && suggestions.length;

const isCityLevel = (destination: any) => destination?.type === 'City';

type Props = {
  i18n: I18nShape;
  configs: ConfigurationShape;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onSuggestionsClearRequested: Function;
  suggestions: AutosuggestSuggestionOrPopularDestinationShape;
  destination?: Maybe<DestinationShape>;
  destinationLabel?: string;
  className?: Maybe<string>;
  lightLabel?: boolean;
  disabled?: boolean;
  arrangeInline?: boolean;
  isMobile?: boolean;
  onChangeOpenCheckinSelector?: Maybe<Function>;
  onChangeInputValue?: Maybe<Function>;
  valid?: Maybe<boolean>;
  elementEventTracker: ElementEventTrackerReturn;
};

type State = {
  value: string;
  visible: boolean;
  lastDisplayed: Maybe<AutosuggestSuggestionShape | PopularDestinationShape>;
  prevProps: Props;
};

const defaultProps = {
  destination: null,
  destinationLabel: undefined,
  className: null,
  lightLabel: false,
  disabled: false,
  arrangeInline: false,
  valid: false,
  isMobile: false,
  onChangeOpenCheckinSelector: undefined,
  onChangeInputValue: undefined,
};

const destinationIsChanging = (nextProps: Props, prevState: State) => {
  const { prevProps } = prevState;
  const { destination: nextDestination } = nextProps;
  const { entity: nextEntity, entityId: nextEntityId } = nextDestination || {};

  const { destination } = prevProps;
  const { entity, entityId } = destination || {};

  return entityId !== nextEntityId || entity !== nextEntity;
};

const localStorage = new LocalStorage('hotelsWebsiteLocalStorage');

class DestinationSelector extends Component<Props, State> {
  static defaultProps = defaultProps;

  highlightedSuggestion: Maybe<
    AutosuggestSuggestionShape | AutosuggestPoiShape
  >;

  isFocused: boolean;

  inputRef: Maybe<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...buildDestinationState(props),
      lastDisplayed: null,
      prevProps: props,
      visible: false,
    };

    this.highlightedSuggestion = null;
    this.isFocused = true;
    this.inputRef = null;

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSuggestionHighlighted = this.onSuggestionHighlighted.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    }, 0);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (destinationIsChanging(nextProps, prevState)) {
      const newState = { ...prevState };
      const { entity: destinationName } = nextProps.destination || {};

      newState.value = destinationName || '';
      newState.prevProps = nextProps;

      const { suggestions } = nextProps;
      if (hasSuggestions(suggestions)) {
        const [lastDisplayed] = suggestions;
        newState.lastDisplayed = lastDisplayed;
      }

      return newState;
    }

    return null;
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  onRefCallback = (element: Maybe<HTMLInputElement>) => {
    if (element) {
      this.inputRef = element;
    }
  };

  onChange(
    _e: Event,
    { newValue }: { newValue: string },
    visible: boolean = false,
  ) {
    this.setState({
      value: newValue,
      visible,
    });
  }

  onClear() {
    this.setState({
      value: '',
    });

    if (this.props.onChangeInputValue) {
      this.props.onChangeInputValue('');
    }
  }

  onFocus = (event: Event) => {
    const {
      arrangeInline,
      configs,
      destination,
      elementEventTracker,
      i18n: { culture },
    } = this.props;
    if (arrangeInline) {
      const target: any = event.currentTarget;
      const { value } = target;
      if (
        value.length > 0 &&
        value.slice(-1) !== ' ' &&
        isCityLevel(destination) &&
        configs?.destinationPlaceholderEnabled
      ) {
        this.onChange(event, { newValue: `${value} ` }, true);
      } else {
        setTimeout(() => target.select(), 10);
      }
    }

    const prefilledQuery = this.state.value;
    const isRecentIncluded = !!(
      (!prefilledQuery || prefilledQuery.length === 0) &&
      JSON.parse(localStorage.tryGetValue('recentSearchItems'))
    );

    const query = {
      prefilledQuery,
      isRecentIncluded,
    };

    const message = buildControlsActivatedMessage(query, culture);
    elementEventTracker.trackAutoSuggest(message);
    logger.event(AUTOSUGGEST_EVENTS.CONTROLS_ACTIVATED);
  };

  onBlur(
    _event: MouseEvent<HTMLDivElement>,
    {
      highlightedSuggestion,
    }: {
      highlightedSuggestion: Maybe<
        AutosuggestSuggestionShape | AutosuggestPoiShape
      >;
    },
  ) {
    const {
      elementEventTracker,
      i18n: { culture },
    } = this.props;

    if (!highlightedSuggestion) {
      const query = this.state.value;
      const message = buildControlsDismissedMessage(query, culture);
      elementEventTracker.trackAutoSuggest(message);
      logger.event(AUTOSUGGEST_EVENTS.CONTROLS_DISMISSED);
    }

    this.autoSelectSuggestion();
  }

  onKeyUp = (e: any) => {
    const { value } = this.state;
    const { destination } = this.props;
    if (
      e.key === 'Backspace' &&
      value.slice(-1) !== ' ' &&
      isCityLevel(destination) &&
      destination?.entity === value
    ) {
      e.currentTarget.select();
    }
  };

  onSuggestionHighlighted({
    suggestion,
  }: {
    suggestion: AutosuggestSuggestionShape;
  }) {
    this.highlightedSuggestion = suggestion;
  }

  onSuggestionSelected(e: Event | {}, item: object) {
    if ((e as Event).preventDefault) {
      (e as Event).preventDefault();
    }

    const {
      elementEventTracker,
      i18n: { culture },
      onChangeOpenCheckinSelector,
      onSuggestionSelected,
    } = this.props;

    const puredItem = getPuredItem(item);
    this.onSuggestionHighlighted(puredItem);
    onSuggestionSelected(puredItem);

    if (
      puredItem.method &&
      !puredItem.isFromRecentSearch &&
      !this.props.isMobile &&
      onChangeOpenCheckinSelector
    ) {
      onChangeOpenCheckinSelector(true);
    }

    if (puredItem.method) {
      const {
        isFromRecentSearch,
        isPopularDestination,
        suggestionIndex,
        suggestionValue,
      } = puredItem;
      const { entityId } = puredItem.suggestion;
      const query = this.state.value;
      const searchType =
        (isFromRecentSearch && RESULT_TYPE.RECENT) ||
        (isPopularDestination && RESULT_TYPE.POPULAR) ||
        RESULT_TYPE.UNSET_RESULT_TYPE;
      const message = buildResultSelectedMessage(
        query,
        suggestionValue,
        suggestionIndex,
        entityId,
        culture,
        searchType,
      );
      elementEventTracker.trackAutoSuggest(message);
      logger.event(AUTOSUGGEST_EVENTS.RESULT_SELECTED);
    }
  }

  autoSelectSuggestion() {
    const { arrangeInline, configs, destination, isMobile, suggestions } =
      this.props;
    const { lastDisplayed, value } = this.state;
    const needToTrim =
      configs?.destinationPlaceholderEnabled &&
      arrangeInline &&
      isCityLevel(destination);
    let valueNew = '';
    if (hasSuggestions(suggestions)) {
      let suggestion: Maybe<
        | AutosuggestSuggestionShape
        | AutosuggestPoiShape
        | PopularDestinationShape
        | ''
      > = this.highlightedSuggestion || suggestions[0] || lastDisplayed;
      if (!value) {
        suggestion = this.highlightedSuggestion || '';
      }
      valueNew =
        suggestion &&
        (
          suggestion as
            | AutosuggestSuggestionShape
            | PopularDestinationItemShape
            | AutosuggestPoiShape
        ).entity
          ? pureSuggestValue(suggestion)
          : '';
      if (!isMobile) {
        const item = { suggestion };
        this.onSuggestionSelected({}, item);
        if (needToTrim && valueNew.slice(-1) === ' ') {
          valueNew = valueNew.trim();
        }
      }
    } else if (needToTrim && value.slice(-1) === ' ') {
      valueNew = value.trim();
    }
    this.setState({
      visible: false,
      value: valueNew,
    });
  }

  renderRecentAndPopulars = (suggestion: PopularDestinationShape) => {
    const { onSuggestionsClearRequested } = this.props;
    return (
      <RecentSearchAndPopulars
        suggestion={suggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onBlur={this.onBlur}
      />
    );
  };

  renderRecentAndPopularsMobile = (suggestion: PopularDestinationShape) => (
    <RecentSearchAndPopularsMobile
      suggestion={suggestion}
      onSuggestionSelected={this.onSuggestionSelected}
      onBlur={this.onBlur}
    />
  );

  renderPoi = (
    cityId: Maybe<string>,
    index: number,
    poi: AutosuggestPoiShape,
    onSuggestionsClearRequested: Function,
  ) => {
    const clickPoi = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      this.onSuggestionSelected(
        {},
        {
          suggestion: poi,
          suggestionIndex: index,
          suggestionValue: poi.entity,
          method: 'click',
        },
      );
      this.onBlur(e, { highlightedSuggestion: poi });
      onSuggestionsClearRequested();
      const { elementEventTracker } = this.props;
      elementEventTracker.trackHotelsAction(
        ACTION_TYPE.AUTO_SUGGEST_POIS_CLICK,
        buildAdditionalInfoAutoSuggestPoiClick({ poiId: poi.entityId, cityId }),
      );
    };

    return (
      <div
        className={cls('DestinationSelector__suggestion--poi')}
        key={poi.entityId}
        role="button"
        onKeyUp={() => {}}
        tabIndex={0}
        onClick={clickPoi}
      >
        {poi.entity}
      </div>
    );
  };

  renderSuggestionIncludePOI = (suggestion: AutosuggestSuggestionShape) => {
    const { onSuggestionsClearRequested } = this.props;
    const { entityId } = suggestion;
    return (
      <section
        className={cls('DestinationSelector__section')}
        data-test-id="autosuggest-suggestion"
      >
        <BpkAutosuggestSuggestion
          value={getSuggestionReplacedValue(suggestion)}
          subHeading={getSubheadingValue(suggestion)}
          icon={getSuggestionIcon(suggestion)}
          data-test-id="autosuggest-suggestion"
          className={cls('DestinationSelector__suggestion')}
          tertiaryLabel={suggestion.label}
        />
        {suggestion.pois && suggestion.pois.length > 0 && (
          <div className={cls('DestinationSelector__suggestion--pois')}>
            {suggestion.pois.map((poi, index) =>
              this.renderPoi(entityId, index, poi, onSuggestionsClearRequested),
            )}
          </div>
        )}
      </section>
    );
  };

  render() {
    const {
      arrangeInline,
      className,
      configs,
      destinationLabel,
      disabled,
      i18n: { translate },
      isMobile,
      lightLabel,
      onSuggestionsClearRequested,
      onSuggestionsFetchRequested,
      suggestions,
      valid,
    } = this.props;
    const { value, visible } = this.state;

    const hasPopular =
      suggestions[0] &&
      (suggestions[0] as PopularDestinationShape).popularDestinations &&
      (
        (suggestions[0] as PopularDestinationShape)
          .popularDestinations as PopularDestinationItemShape[]
      ).length > 0;

    const inputId = 'destination-autosuggest';
    const inputClassName = cls('DestinationSelector__input');
    const clearButtonMode = arrangeInline
      ? CLEAR_BUTTON_MODES.never
      : CLEAR_BUTTON_MODES.whileEditing;

    const inputProps = {
      className: inputClassName,
      id: inputId,
      name: inputId,
      placeholder: translate('SearchControls_label_Destination_placeholder'),
      value,
      onChange: this.onChange,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyUp: configs?.destinationPlaceholderEnabled
        ? this.onKeyUp
        : () => null,
      disabled,
      autoComplete: 'off',
      autoCorrect: 'off',
      autoCapitalize: 'off',
      spellCheck: 'false',
      clearButtonMode,
      clearButtonLabel: translate('Button_clear_input'),
      onClear: this.onClear,
      large: isMobile,
      valid,
    };

    const shouldRenderPopular = hasPopular && !value;
    const originalSuggestions = hasPopular ? [] : suggestions;
    const showSuggestions = shouldRenderPopular
      ? suggestions
      : originalSuggestions;

    const extraProps = isMobile
      ? {
          renderSuggestion: shouldRenderPopular
            ? this.renderRecentAndPopularsMobile
            : renderSuggestion,
          inputProps: { ...inputProps, inputRef: this.onRefCallback },
          alwaysRenderSuggestions: true,
          theme: {
            suggestionsContainerOpen: cls(
              'DestinationSelector__suggestionsContainerOpen',
            ),
            suggestionsList: cls('DestinationSelector__suggestionsList'),
            suggestion: cls('DestinationSelector__suggestionItem'),
            suggestionHighlighted: cls(
              'DestinationSelector__suggestionItem--highlighted',
            ),
          },
        }
      : {
          renderSuggestion: shouldRenderPopular
            ? this.renderRecentAndPopulars
            : this.renderSuggestionIncludePOI,
          inputProps,
          shouldRenderSuggestions: () => this.isFocused,
          onSuggestionsClearRequested,
        };

    return (
      <FormField
        className={className}
        fieldId={inputId}
        label={destinationLabel}
        lightLabel={lightLabel}
      >
        <BpkAutosuggest
          suggestions={showSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          getSuggestionValue={pureSuggestValue}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionHighlighted={this.onSuggestionHighlighted}
          highlightFirstSuggestion
          focusInputOnSuggestionClick={false}
          includeIcon
          includeSubheading
          includeTertiaryLabel
          {...extraProps}
        />
        {configs?.destinationPlaceholderEnabled && visible && !isMobile && (
          <PlaceHolder value={value} />
        )}
      </FormField>
    );
  }
}

export default withConfiguration(
  withElementEventTracker(withI18n(DestinationSelector)),
);
