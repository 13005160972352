import get from 'lodash/get';
import { matchPath, withRouter } from 'react-router-dom';

import type { HistoryShape, LocationShape } from '../types';

type Props = {
  hash: string;
  render: Function;
  history: HistoryShape;
  location: LocationShape;
};

const HashRoute = ({ hash, history, location, render }: Props) => {
  const match = matchPath(location.hash, hash);
  const onClose = () => {
    const pushHashByRouteLink = get(
      window,
      'history.state.state.pushHashByRouteLink',
    );
    if (pushHashByRouteLink) {
      history.goBack();
    } else {
      history.push({ ...location, hash: null });
    }
  };
  if (match && render) {
    return render({ history, location, match, onClose });
  }
  return null;
};

export default withRouter(HashRoute);
