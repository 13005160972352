import type { MouseEventHandler } from 'react';

import BpkLargeCalendarIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { ParagraphXs } from '../../../../../../../components/HotelsCommon/Text';
import { withI18n, DATE_FORMAT } from '../../../../skyscanner-application/i18n';

import type { I18nShape } from '../../../../skyscanner-application/i18n';

import STYLES from './DateRange.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nShape;
  onClickLink: MouseEventHandler<HTMLDivElement>;
  checkInDate: Date;
  checkOutDate: Date;
};

const DateRange = ({
  checkInDate,
  checkOutDate,
  i18n: { formatDate },
  onClickLink,
}: Props) => (
  <div
    role="button"
    tabIndex={0}
    onKeyUp={() => {}}
    onClick={onClickLink}
    className={cls('DateRange')}
  >
    <BpkLargeCalendarIcon className={cls('DateRange__icon')} />
    <button type="button">
      <ParagraphXs>
        {formatDate(checkInDate, DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR)}
      </ParagraphXs>
      <ParagraphXs>
        {formatDate(checkOutDate, DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR)}
      </ParagraphXs>
    </button>
  </div>
);

export default withI18n(DateRange);
