import { isNil } from '../is-nil';

const objValues = (obj) => Object.keys(obj).map((key) => obj[key]);

export default objValues;

export const objToKeyValueArray = (obj) => {
  if (isNil(obj)) {
    return [];
  }

  return Object.keys(obj).map((key) => ({
    key,
    value: obj[key],
  }));
};

export const objectKeyToValueArray = (obj) =>
  Object.keys(obj).map((key) => {
    const result = {};
    result[key] = obj[key];
    return result;
  });
