import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { withI18n } from '../../../../skyscanner-application/i18n';

import type { I18nShape } from '../../../../skyscanner-application/i18n';

import STYLES from './PlaceHolder.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nShape;
  value: string;
};

const PlaceHolder = ({ i18n: { translate }, value }: Props) => (
  <div className={cls('PlaceHolder')}>
    <span className={cls('PlaceHolder__destination')}>{value}</span>
    <span>
      &nbsp;
      {translate('SearchControls_label_enterHotelOrLandmark')}
    </span>
  </div>
);

export default withI18n(PlaceHolder);
