import type { ReactElement, ComponentType } from 'react';

import withScrimmedPortal from '../Common/withScrimmedPortal';
import displayName from '../display-name';

import HashRoute from './HashRoute';

import type { HistoryShape, LocationShape } from '../types';

interface BasicProps {
  hash: string;
}

interface ChildrenProps extends BasicProps {
  children: ReactElement;
  render?: undefined;
}
interface RenderProps extends BasicProps {
  render: Function;
  children?: undefined;
}

type RouterPropsShape = {
  history: HistoryShape;
  location: LocationShape;
  match: boolean;
  onClose: Function;
};

const withHashRoutePortal = <P extends object>(Wrapper: ComponentType<P>) => {
  const ScrimmedPortal = withScrimmedPortal(Wrapper);
  const ScrimmedRouter = ({
    children: propsChildren,
    hash,
    render,
    ...rest
  }: (ChildrenProps | RenderProps) & Omit<P, 'onClose'>) => (
    <HashRoute
      hash={hash}
      render={(routeProps: RouterPropsShape) => {
        const { onClose } = routeProps;
        const children = render ? render(routeProps) : propsChildren;
        return (
          <ScrimmedPortal
            {...rest}
            isOpen
            neverCloseFromPortal
            onClose={onClose}
          >
            {children}
          </ScrimmedPortal>
        );
      }}
    />
  );

  ScrimmedRouter.displayName = displayName(Wrapper);

  return ScrimmedRouter;
};

export default withHashRoutePortal;
