import type { ReactElement } from 'react';

import {
  withAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import {
  lineHeightXs,
  lineHeightSm,
  lineHeightBase,
  lineHeightLg,
  lineHeightXl,
  iconSizeSm,
  iconSizeLg,
} from '@skyscanner/bpk-foundations-web/tokens/base.es6';

export const alignIconTextXs = (icon: ReactElement) =>
  withAlignment(withRtlSupport(icon), lineHeightXs, iconSizeSm);

export const alignIconTextSm = (icon: ReactElement) =>
  withAlignment(withRtlSupport(icon), lineHeightSm, iconSizeSm);

export const alignIconTextBase = (icon: ReactElement) =>
  withAlignment(withRtlSupport(icon), lineHeightBase, iconSizeSm);

export const alignIconTextLg = (icon: ReactElement) =>
  withAlignment(withRtlSupport(icon), lineHeightLg, iconSizeLg);

export const alignIconLgTextBase = (icon: ReactElement) =>
  withAlignment(withRtlSupport(icon), lineHeightBase, iconSizeLg);

export const alignIconTextXl = (icon: ReactElement) =>
  withAlignment(withRtlSupport(icon), lineHeightXl, iconSizeSm);

export const AlignedSpanIconSmTextSm = withAlignment(
  'span',
  iconSizeSm,
  lineHeightSm,
);

export const AlignedSpanIconLgTextBase = withAlignment(
  'span',
  iconSizeLg,
  lineHeightBase,
);
