import { useRef } from 'react';

import BpkInput, {
  withOpenEvents,
} from '@skyscanner/backpack-web/bpk-component-input';
import BpkPopover from '@skyscanner/backpack-web/bpk-component-popover';

import { withI18n } from '../../../../skyscanner-application/i18n';
import GuestsRoomsChildren from '../GuestsRoomsChildren';
import getGuestsRoomsChildrenValue from '../getGuestsRoomsChildrenValue';

import type { I18nShape } from '../../../../skyscanner-application/i18n';
import type { Maybe } from '../../../types';

import STYLES from './GuestsRoomsChildrenPopover.module.scss';

const EnhancedInput = withOpenEvents(BpkInput);

type Props = {
  i18n: I18nShape;
  isOpen: boolean;
  adults: number;
  rooms: number;
  childrenAges: string[];
  guestsRoomsFieldId: string;
  popoverClassName?: Maybe<string>;
  onOpen: () => void;
  onClose: Function;
  onGuestsRoomsChildrenChanged: Function;
};

const GuestsRoomsChildrenPopover = ({
  adults,
  childrenAges,
  guestsRoomsFieldId,
  i18n,
  isOpen,
  onClose,
  onGuestsRoomsChildrenChanged,
  onOpen,
  popoverClassName = undefined,
  rooms,
}: Props) => {
  const target = useRef(null);

  return (
    <div className={STYLES.GuestsRoomsChildrenPopover}>
      <BpkPopover
        id="guest-rooms-children-popover"
        target={
          <div ref={target}>
            <EnhancedInput
              className={
                popoverClassName ||
                STYLES.GuestsRoomsChildrenPopover__popoverInput
              }
              id={guestsRoomsFieldId}
              name={guestsRoomsFieldId}
              value={getGuestsRoomsChildrenValue({
                adults,
                rooms,
                childrenAges,
                translatePlural: i18n.translatePlural,
              })}
              isOpen={isOpen}
              onOpen={onOpen}
              hasTouchSupport
              data-test-id={guestsRoomsFieldId}
            />
          </div>
        }
        onClose={onClose}
        isOpen={isOpen}
        label="Popover"
        closeButtonText={i18n.translate('HotelStay_label_Done')}
      >
        <div className={STYLES.GuestsRoomsChildrenPopover__popoverContent}>
          <GuestsRoomsChildren
            adults={adults}
            rooms={rooms}
            childrenAges={childrenAges}
            onGuestsRoomsChildrenChanged={onGuestsRoomsChildrenChanged}
          />
        </div>
      </BpkPopover>
    </div>
  );
};

export default withI18n(GuestsRoomsChildrenPopover);
