import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { Paragraph } from '../../../../../../../components/HotelsCommon/Text';
import { withI18n } from '../../../../skyscanner-application/i18n';

import type { I18nShape } from '../../../../skyscanner-application/i18n';
import type { Maybe } from '../../../types';

import STYLES from './ApplyFooter.scss';

type Props = {
  checkOut?: Maybe<Date>;
  showCalculatedPrice: boolean;
  disabled?: boolean;
  averagePrice?: number;
  nightCount?: number;
  minPrice?: number;
  i18n: I18nShape;
  onApply: Function;
};

const cls = cssModules(STYLES);

const ApplyFooter = ({
  averagePrice = undefined,
  checkOut = undefined,
  disabled = false,
  i18n: { formatCurrency, translate, translatePlural },
  minPrice = undefined,
  nightCount = 0,
  onApply,
  showCalculatedPrice,
}: Props) => (
  <div className={cls('ApplyFooter')}>
    {checkOut && (
      <div className={cls('ApplyFooter__applyContent')}>
        {showCalculatedPrice && averagePrice ? (
          <Paragraph>
            {translate('DateRangeSelector_applyLabel_pricePerNight', {
              cost: formatCurrency(averagePrice),
            })}
          </Paragraph>
        ) : (
          ''
        )}
        <div className={cls('ApplyFooter__applyNight')}>
          <Paragraph>
            {nightCount &&
              translatePlural(
                'SelectionSummary_label_nights',
                nightCount,
                'number',
              )}
            &nbsp;
            <span>
              {showCalculatedPrice && minPrice
                ? translate('DateRangeSelector_applyLable_FromPrice', {
                    0: formatCurrency(minPrice),
                  })
                : ''}
            </span>
          </Paragraph>
        </div>
      </div>
    )}
    {!checkOut && (
      <div className={cls('ApplyFooter__selectLabel')}>
        <Paragraph>
          {translate('label_calendar_select_checkIn_date_footer')}
        </Paragraph>
      </div>
    )}
    <div>
      <BpkButton
        id="applyFooter__apply"
        onClick={onApply}
        className={cls('ApplyFooter__applyButton')}
        disabled={disabled}
      >
        {translate('HotelStay_label_Done')}
      </BpkButton>
    </div>
  </div>
);

export default withI18n(ApplyFooter);
