const transformPrice = (i18n, price) =>
  new Map([
    [
      'EXPENSIVE',
      {
        text: '£££',
        price: `${i18n.formatCurrency(price)}`,
      },
    ],
    [
      'MODERATE',
      {
        text: '££',
        price: `${i18n.formatCurrency(price)}`,
      },
    ],
    [
      'CHEAP',
      {
        text: '£',
        price: `${i18n.formatCurrency(price)}`,
      },
    ],
    [
      'DEFAULT',
      {
        text: ' ',
        price: '-',
      },
    ],
  ]);
export default transformPrice;
