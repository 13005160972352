import ObserverClient from '../observer-client';

import type { ObserverClientReturn } from '../types';

type Props = {
  environment: 'development' | 'production' | 'tests';
};

const buildObserverClient = ({ environment }: Props): ObserverClientReturn => {
  if (environment === 'development') {
    return ObserverClient({
      destinations: ['console'],
    });
  }

  if (environment === 'production') {
    return ObserverClient({ destinations: ['observer'] });
  }

  return ObserverClient({ destinations: ['console', 'event'] });
};

export default buildObserverClient;
