import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { Paragraph } from '../../../../../../../components/HotelsCommon/Text';
import { withI18n } from '../../../../skyscanner-application/i18n';
import PopularDestination from '../PopularDestination';
import RecentSearches from '../RecentSearches';

import type { I18nShape } from '../../../../skyscanner-application/i18n';
import type { PopularDestinationShape } from '../../../types';

import STYLES from './RecentSearchAndPopulars.scss';

type Props = {
  i18n: I18nShape;
  suggestion: PopularDestinationShape;
  onSuggestionSelected: Function;
  onSuggestionsClearRequested: Function;
  onBlur: Function;
};

const cls = cssModules(STYLES);

const RecentSearchAndPopulars = ({
  i18n: { translate },
  onBlur,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  suggestion,
}: Props) => {
  const hasPopular =
    suggestion.popularDestinations && suggestion.popularDestinations.length > 0;
  return (
    <section className={cls('RecentSearchAndPopulars')}>
      <RecentSearches
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onBlur={onBlur}
      />
      {hasPopular && (
        <section>
          <Paragraph className={cls('RecentSearchAndPopulars__popularTitle')}>
            {translate('Auto_Suggest_popularDestination_label')}
          </Paragraph>
          <div className={cls('RecentSearchAndPopulars__popularDestinations')}>
            {suggestion.popularDestinations &&
              suggestion.popularDestinations.map((popular, index) => (
                <PopularDestination
                  key={popular.entityId}
                  popular={popular}
                  index={index}
                  onSuggestionSelected={onSuggestionSelected}
                  onBlur={onBlur}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                />
              ))}
          </div>
        </section>
      )}
    </section>
  );
};

export default withI18n(RecentSearchAndPopulars);
