import GLOBAL from 'saddlebag-browser/src/GLOBAL';

export const isTestEnv = () => {
  const $window = GLOBAL.getWindow();
  return $window.location.hostname === 'localhost';
};

export const isProdEnv = () => process.env.NODE_ENV === 'production';

export const isStagingEnv = () => process.env.NODE_ENV === 'staging';
