import PropTypes from 'prop-types';
import { Component } from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { InlineText } from '../../../../../../components/HotelsCommon/Text';
import {
  applyCheckInDateRestrictions,
  applyCheckOutDateRestrictions,
} from '../../../../../common/src/services/guest-stay';
import stayLength from '../../../../../common/src/services/guest-stay/stay-length';
import { withI18n } from '../../../skyscanner-application/i18n';
import { I18nShape } from '../../../skyscanner-application/i18n/i18n-shape';
import { CalendarPriceDataShape } from '../../shapes';
import FormField from '../FormField';
import { withPriceData } from '../PriceDataProvider';

import DatePicker from './DatePicker';

import STYLES from './DateRangeSelector.scss';

const cls = cssModules(STYLES);

class DateRangeSelector extends Component {
  state = {
    isCheckOutDateOpen: false,
  };

  handleCheckInChange = (newCheckInDate) => {
    const { checkOutDate, onDatesChanged, openCheckinSelector } = this.props;
    const newCheckOutDate = applyCheckOutDateRestrictions(
      newCheckInDate,
      checkOutDate,
    );
    onDatesChanged(newCheckInDate, newCheckOutDate);

    // The checkout date selector should open on check in date select as part of an experiment
    // controlled via the openCheckinSelector prop
    if (openCheckinSelector) {
      this.setState({
        isCheckOutDateOpen: true,
      });
    }
  };

  handleCheckOutChange = (newCheckOutDate) => {
    const { checkInDate, onChangeOpenCheckinSelector, onDatesChanged } =
      this.props;
    const newCheckInDate = applyCheckInDateRestrictions(
      checkInDate,
      newCheckOutDate,
    );
    onDatesChanged(newCheckInDate, newCheckOutDate);

    if (onChangeOpenCheckinSelector) {
      onChangeOpenCheckinSelector(false);
    }
  };

  handleCheckOutOpenChange = (isOpen) => {
    const { onChangeOpenCheckinSelector } = this.props;
    this.setState({
      isCheckOutDateOpen: isOpen,
    });
    if (onChangeOpenCheckinSelector) {
      onChangeOpenCheckinSelector(false);
    }
  };

  handleCheckInOpenChange = (isOpen) => {
    const { onChangeOpenCheckinSelector, openCheckinSelector } = this.props;
    if (onChangeOpenCheckinSelector && openCheckinSelector !== isOpen) {
      onChangeOpenCheckinSelector(isOpen);
    }
  };

  render() {
    const {
      checkInDate,
      checkOutDate,
      className,
      disabled,
      formFieldClassName,
      i18n: { translate, translatePlural },
      lightLabel,
      newHeader,
      openCheckinSelector,
      priceData,
      showLabel,
      showNights,
    } = this.props;
    const { isCheckOutDateOpen } = this.state;

    const inputProps = {
      className: cls(!showNights && 'DateRangeSelector__input'),
      disabled,
      readOnly: true,
    };

    const checkInFieldId = 'checkin';
    const checkOutFieldId = 'checkout';

    return (
      <div
        className={cls(
          'DateRangeSelector',
          className,
          showNights && 'DateRangeSelector__showNights',
        )}
      >
        <FormField
          fieldId={checkInFieldId}
          label={showLabel && translate('SearchControls_label_CheckIn')}
          lightLabel={lightLabel}
          className={cls(
            formFieldClassName,
            showNights && 'DateRangeSelector__checkInForm',
          )}
          newHeaderDateRange={newHeader}
        >
          <DatePicker
            id={checkInFieldId}
            dateData={priceData}
            onDateSelect={this.handleCheckInChange}
            startDate={checkInDate}
            endDate={checkOutDate}
            date={checkInDate}
            inputProps={inputProps}
            popperModifiers={[
              {
                name: 'flip',
                options: { enabled: false },
              },
            ]}
            isOpen={openCheckinSelector}
            onOpenChange={this.handleCheckInOpenChange}
          />
        </FormField>
        {showNights && (
          <div className={cls('DateRangeSelector__nights')}>
            <InlineText>
              {translatePlural(
                'SelectionSummary_label_nights',
                stayLength({ checkIn: checkInDate, checkOut: checkOutDate }),
                'number',
              )}
            </InlineText>
          </div>
        )}
        <FormField
          fieldId={checkOutFieldId}
          label={showLabel && translate('SearchControls_label_CheckOut')}
          lightLabel={lightLabel}
          className={cls(
            formFieldClassName,
            showNights && 'DateRangeSelector__checkoutForm',
          )}
          newHeaderDateRange={newHeader}
        >
          <DatePicker
            id={checkOutFieldId}
            dateData={priceData}
            onDateSelect={this.handleCheckOutChange}
            startDate={checkInDate}
            endDate={checkOutDate}
            date={checkOutDate}
            inputProps={inputProps}
            popperModifiers={[
              {
                name: 'flip',
                options: { enabled: false },
              },
            ]}
            isOpen={isCheckOutDateOpen}
            onOpenChange={this.handleCheckOutOpenChange}
            isCheckOut
          />
        </FormField>
      </div>
    );
  }
}

DateRangeSelector.propTypes = {
  i18n: I18nShape.isRequired,
  checkInDate: PropTypes.instanceOf(Date).isRequired,
  checkOutDate: PropTypes.instanceOf(Date).isRequired,
  onDatesChanged: PropTypes.func.isRequired,
  className: PropTypes.string,
  formFieldClassName: PropTypes.string,
  lightLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  openCheckinSelector: PropTypes.bool,
  newHeader: PropTypes.bool,
  showNights: PropTypes.bool,
  showLabel: PropTypes.bool,
  priceData: CalendarPriceDataShape,
  onChangeOpenCheckinSelector: PropTypes.func,
};

DateRangeSelector.defaultProps = {
  className: null,
  formFieldClassName: null,
  lightLabel: false,
  disabled: false,
  openCheckinSelector: false,
  newHeader: false,
  showNights: false,
  showLabel: false,
  onChangeOpenCheckinSelector: null,
  priceData: null,
};

export default withI18n(withPriceData(DateRangeSelector));
