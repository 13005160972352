import { Component } from 'react';

import { LocalStorage } from 'saddlebag-localstorage';

import BpkText from '@skyscanner/backpack-web/bpk-component-text';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import PageTypes from '../../../../common/src/pageTypes';
import stayLength from '../../../../common/src/services/guest-stay/stay-length';
import { now } from '../../../../common/src/utils/wrap-date';
import { withMetrics } from '../../skyscanner-application/application-metrics';
import { withElementEventTracker } from '../../skyscanner-application/element-events';
import { withI18n } from '../../skyscanner-application/i18n';
import {
  ACTION_TYPE,
  ENTITY_TYPE,
} from '../../skyscanner-application/minievents/constants';
import { buildAdditionalInfoDayViewSearch } from '../../skyscanner-application/minievents/hotels-action';
import { withUserContextProvider } from '../UserContextProvider';

import AutosuggestDataProvider from './AutosuggestDataProvider';
import ChappedLayout from './ChappedLayout';
import ExpandableLayout from './ExpandableLayout';
import HorizontalLayout from './HorizontalLayout';
import PriceDataProvider from './PriceDataProvider';

import type { ElementEventTrackerReturn } from '../../skyscanner-application/element-events';
import type { I18nShape } from '../../skyscanner-application/i18n';
import type { MetricsType } from '../../skyscanner-application/types';
import type {
  StayShape,
  Maybe,
  SearchDestinationShape,
  SearchSuggestion,
  MappingShape,
} from '../types';
import type {
  DestinationShape,
  UserContextShape,
} from 'common-types/types/hotels-components/types';

import STYLES from './SearchControls.scss';

const cls = cssModules(STYLES);

const LAYOUT: MappingShape = {
  CHAPPED: 'chapped',
  HORIZONTAL: 'horizontal',
  EXPANDABLE: 'expandable',
};

const SEARCH_ACTION_TYPE: MappingShape = {
  [PageTypes.SEARCH]: ACTION_TYPE.DAY_VIEW_SEARCH,
  [PageTypes.HOME]: ACTION_TYPE.HOME_PAGE_SEARCH,
};

type Props = {
  i18n: I18nShape;
  elementEventTracker: ElementEventTrackerReturn;
  onSubmit: Function;
  submittingForm?: boolean;
  destinationLabel: string;
  lightLabel?: boolean;
  metrics: MetricsType;
  openCheckinSelector?: boolean;
  freeCancellation?: boolean;
  /* eslint-disable react/no-unused-prop-types */
  destination: DestinationShape;
  stay: Required<StayShape>;
  prefilledDestination?: DestinationShape;
  showPreference: boolean;
  /* eslint-enable react/no-unused-prop-types */
  arrangeInline?: boolean;
  isMobile: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  searchWithChildren?: boolean;
  shouldAutoSearch?: boolean;
  featureRef: any;
  className?: string;
  layout?: keyof typeof LAYOUT;
  userContext: UserContextShape;
};

type State = {
  destination: DestinationShape;
  checkInDate: Date;
  checkOutDate: Date;
  adults: number;
  rooms: number;
  childrenAges: string[];
  freeCancellation: Maybe<boolean>;
  openCheckinSelector: Maybe<boolean>;
  showPreference: boolean;
  currentProps: {
    destination: DestinationShape;
    stay: Required<StayShape>;
    prefilledDestination?: DestinationShape;
  };
  urlFilters: null;
  miniEventFilters: null;
  destinationInput: Maybe<string>;
  validDestination: boolean;
};

const defaultProps = {
  submittingForm: false,
  lightLabel: true,
  openCheckinSelector: false,
  freeCancellation: false,
  arrangeInline: false,
  shouldAutoSearch: false,
  prefilledDestination: undefined,
  searchWithChildren: undefined,
  className: undefined,
  layout: undefined,
};

const localStorage = new LocalStorage('hotelsWebsiteLocalStorage');
const buildSearchState = (props: Props, state?: State) => {
  const {
    destination,
    freeCancellation,
    prefilledDestination,
    showPreference,
    stay,
  } = props;
  const { checkIn, checkOut, childrenAges, numberOfAdults, numberOfRooms } =
    stay || {};

  const { destination: existingDestination } = state || {};

  return {
    destination: destination || existingDestination || prefilledDestination,
    checkInDate: checkIn,
    checkOutDate: checkOut,
    adults: numberOfAdults,
    rooms: numberOfRooms,
    childrenAges,
    freeCancellation,
    showPreference,
    currentProps: {
      destination,
      prefilledDestination,
      stay,
    },
  };
};

const searchPropsAreChanging = (nextProps: Props, prevState: State) => {
  const {
    destination: nextDestination,
    prefilledDestination: nextPrefilledDestination,
    stay: {
      checkIn: nextCheckIn,
      checkOut: nextCheckOut,
      numberOfAdults: nextNumberOfAdults,
      numberOfRooms: nextNumberOfRooms,
    },
  } = nextProps;
  const { entity: nextEntity, entityId: nextEntityId } = nextDestination || {};
  const { entityId: nextPrefilledEntityId } = nextPrefilledDestination || {};

  const {
    destination,
    prefilledDestination,
    stay: { checkIn, checkOut, numberOfAdults, numberOfRooms },
  } = prevState.currentProps;
  const { entityId: prefilledEntityId } = prefilledDestination || {};
  const { entity, entityId } = destination || {};

  return (
    entityId !== nextEntityId ||
    entity !== nextEntity ||
    prefilledEntityId !== nextPrefilledEntityId ||
    checkIn !== nextCheckIn ||
    checkOut !== nextCheckOut ||
    numberOfAdults !== nextNumberOfAdults ||
    numberOfRooms !== nextNumberOfRooms
  );
};

const hasDestination = (destination: DestinationShape) =>
  !!(destination && destination.entityId);

class SearchControls extends Component<Props, State> {
  static defaultProps = defaultProps;

  searchItems: any[];

  constructor(props: Props) {
    super(props);

    this.state = {
      destinationInput: null,
      ...buildSearchState(props),
      openCheckinSelector: this.props.openCheckinSelector,
      urlFilters: null,
      miniEventFilters: null,
      validDestination: true,
    };

    this.searchItems = [];
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (searchPropsAreChanging(nextProps, prevState)) {
      return buildSearchState(nextProps, prevState);
    }

    return null;
  }

  onDestinationSuggestionsRequested = ({ value }: { value: string }) => {
    this.setState({ destinationInput: value });
  };

  onChangeInputValue = (value: string) => {
    const { destinationInput } = this.state;
    if (destinationInput !== value) {
      this.setState({ destinationInput: value });
    }
  };

  onDestinationChange = ({ suggestion }: { suggestion: SearchSuggestion }) => {
    this.setState(
      {
        // @ts-ignore
        destination: suggestion,
      },
      () => this.validateForm(),
    );
  };

  onChangeOpenCheckinSelector = (isOpen: boolean) => {
    const { isMobile } = this.props;
    const isAutoOpenCalender = !isMobile;

    if (isAutoOpenCalender) {
      this.setState({
        openCheckinSelector: isOpen,
      });
    }
  };

  onRecentSearchesChange = (item: SearchDestinationShape) => {
    const { checkInDate: checkInState, checkOutDate: checkOutState } =
      this.state;
    const {
      adults,
      checkInDate: checkInRecent,
      checkOutDate: checkOutRecent,
      childrenAges,
      rooms,
    } = item;

    if (checkInRecent) {
      const futureDate = stayLength({
        checkIn: now(),
        checkOut: checkInRecent,
      });
      const checkInDate = futureDate >= 0 ? checkInRecent : checkInState;
      const checkOutDate = futureDate >= 0 ? checkOutRecent : checkOutState;
      this.onDatesChanged(checkInDate, checkOutDate);
      this.setState({
        adults,
        childrenAges,
        rooms,
      });
    }
  };

  onDatesChanged = (checkInDate: Date, checkOutDate: Date) => {
    this.setState({
      checkInDate,
      checkOutDate,
    });
  };

  onDatesRangeApply = (checkInDate: Date, checkOutDate: Date) => {
    this.onDatesChanged(checkInDate, checkOutDate);
    if (this.props.shouldAutoSearch) {
      setTimeout(() => {
        this.onSubmitClick();
        this.onSubmit();
      }, 0);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  onPeopleRoomChange = ({
    adults,
    rooms,
  }: {
    adults: number;
    rooms: number;
  }) => {
    this.setState({
      adults,
      rooms,
    });
  };

  getGuestsRoomsChildren = (
    rooms: number,
    adults: number,
    childrenAges: string[],
    e: Event,
  ) => {
    this.setState(
      {
        rooms,
        adults,
        childrenAges,
      },
      () => {
        if (this.props.shouldAutoSearch) {
          this.onSubmitClick();
          this.onSubmit(e);
        }
      },
    );
  };

  onSubmitClick = () => {
    this.onChangeOpenCheckinSelector(false);
    const { elementEventTracker, metrics } = this.props;
    try {
      metrics.searchClicked();
      const { destination, miniEventFilters } = this.state;
      const props = { ...destination };
      const searchWithChildren: boolean =
        this.state.childrenAges && this.state.childrenAges.length > 0;

      const { entity, entityId, type } = props;
      const additionalInfoDayViewSearch = {
        entityID: parseInt(entityId as string, 10),
        entityName: entity,
        IsSearchWithChildren: searchWithChildren,
        // @ts-ignore
        entityType: type && ENTITY_TYPE[type.toUpperCase()],
        filters: null,
      };

      if (miniEventFilters) {
        additionalInfoDayViewSearch.filters = miniEventFilters;
      }

      const actionType = SEARCH_ACTION_TYPE[this.props.userContext.pageType];
      if (!actionType) {
        // TODO: we may also need to track the search action on the details page
        return;
      }
      elementEventTracker.trackHotelsAction(
        actionType,
        buildAdditionalInfoDayViewSearch(additionalInfoDayViewSearch),
      );
    } catch (e) {
      // Quietly catching all errors to make sure the form is submitted
    }
  };

  onSubmit = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    const { metrics, onSubmit } = this.props;
    if (this.validateForm()) {
      const {
        adults,
        checkInDate,
        checkOutDate,
        childrenAges,
        destination,
        freeCancellation,
        rooms,
        urlFilters,
      } = this.state;

      this.searchItems.push({
        destination,
        checkInDate,
        checkOutDate,
        adults,
        childrenAges,
        rooms,
      });
      localStorage.trySetValue(
        'recentSearchItems',
        JSON.stringify(this.searchItems.reverse().slice(0, 5)),
      );

      metrics.searchSubmitted();
      onSubmit({
        destination,
        checkIn: checkInDate,
        checkOut: checkOutDate,
        numberOfAdults: adults,
        numberOfRooms: rooms,
        childrenAges,
        freeCancellation,
        filters: urlFilters,
      });
    } else {
      metrics.searchValidationFailed();
    }
  };

  getPreference = (filters: any) => {
    const { miniEventFilters, urlFilters } = filters;
    const urlFilterKeys = Object.keys(urlFilters);
    this.setState({
      urlFilters: urlFilterKeys.length > 0 ? urlFilters : null,
      miniEventFilters: urlFilterKeys.length > 0 ? miniEventFilters : null,
    });
  };

  onSuggestionSelected = (item: SearchDestinationShape) => {
    this.onDestinationChange(item);
    const { isFromRecentSearch } = item;
    if (isFromRecentSearch) {
      this.onRecentSearchesChange(item);
    }
    if (this.props.shouldAutoSearch || isFromRecentSearch) {
      setTimeout(() => {
        this.onSubmitClick();
        this.onSubmit();
      }, 0);
    }
  };

  validateForm() {
    const { destination } = this.state;
    const hasValue = hasDestination(destination);
    this.setState({
      validDestination: hasValue,
    });
    return hasValue;
  }

  render() {
    const {
      arrangeInline,
      className,
      destinationLabel,
      featureRef,
      freeCancellation,
      i18n: { translate },
      layout,
      lightLabel,
      shouldAutoSearch,
      submittingForm,
    } = this.props;
    const {
      adults,
      checkInDate,
      checkOutDate,
      childrenAges,
      destination,
      destinationInput,
      openCheckinSelector,
      rooms,
      showPreference,
      validDestination,
    } = this.state;

    return (
      <PriceDataProvider destination={destination}>
        {!validDestination && (
          <div
            className={cls(
              arrangeInline
                ? 'SearchControls__destinationErr--inline'
                : 'SearchControls__destinationErr--default',
            )}
          >
            <BpkText className={cls('SearchControls__destinationErr--message')}>
              {translate('SearchControls_label_Destination_error')}
            </BpkText>
          </div>
        )}
        <form
          data-test-id="search-controls"
          onSubmit={this.onSubmit}
          className={cls(arrangeInline && 'SearchControls', className)}
          ref={featureRef}
        >
          <AutosuggestDataProvider input={destinationInput}>
            {(suggestions, onClearSuggestions) => {
              if (layout === LAYOUT.HORIZONTAL) {
                return (
                  <HorizontalLayout
                    suggestions={suggestions}
                    destination={destination}
                    destinationLabel={destinationLabel}
                    submittingForm={submittingForm}
                    lightLabel={lightLabel}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    adults={adults}
                    rooms={rooms}
                    childrenAges={childrenAges}
                    onChangeInputValue={this.onChangeInputValue}
                    onDatesRangeApply={this.onDatesRangeApply}
                    onSuggestionsFetchRequested={
                      this.onDestinationSuggestionsRequested
                    }
                    onSuggestionSelected={this.onSuggestionSelected}
                    getGuestsRoomsChildren={this.getGuestsRoomsChildren}
                    onChangeOpenCheckinSelector={
                      this.onChangeOpenCheckinSelector
                    }
                    onClearSuggestions={onClearSuggestions}
                    validDestination={validDestination ? null : false}
                    showSearchButton={!shouldAutoSearch}
                  />
                );
              }

              if (layout === LAYOUT.CHAPPED) {
                return (
                  <ChappedLayout
                    suggestions={suggestions}
                    destination={destination}
                    destinationLabel={destinationLabel}
                    lightLabel={lightLabel}
                    searchButtonLabel={translate('SearchControls_label_Submit')}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    adults={adults}
                    rooms={rooms}
                    childrenAges={childrenAges}
                    onSuggestionsFetchRequested={
                      this.onDestinationSuggestionsRequested
                    }
                    onSuggestionSelected={this.onSuggestionSelected}
                    getGuestsRoomsChildren={this.getGuestsRoomsChildren}
                    onDatesChanged={this.onDatesRangeApply}
                    onSubmit={this.props.onSubmit}
                    onClearSuggestions={onClearSuggestions}
                    arrangeInline={arrangeInline}
                  />
                );
              }

              return (
                <ExpandableLayout
                  suggestions={suggestions}
                  destination={destination}
                  destinationLabel={destinationLabel}
                  submittingForm={submittingForm}
                  lightLabel={lightLabel}
                  checkInDate={checkInDate}
                  checkOutDate={checkOutDate}
                  adults={adults}
                  rooms={rooms}
                  childrenAges={childrenAges}
                  onSuggestionsFetchRequested={
                    this.onDestinationSuggestionsRequested
                  }
                  onSuggestionSelected={this.onSuggestionSelected}
                  getGuestsRoomsChildren={this.getGuestsRoomsChildren}
                  onDatesChanged={this.onDatesChanged}
                  openCheckinSelector={openCheckinSelector}
                  onChangeOpenCheckinSelector={this.onChangeOpenCheckinSelector}
                  onClearSuggestions={onClearSuggestions}
                  onGetPreference={this.getPreference}
                  onSubmitClick={this.onSubmitClick}
                  showPreference={showPreference}
                  freeCancellation={freeCancellation}
                  arrangeInline={arrangeInline}
                  validDestination={validDestination ? null : false}
                />
              );
            }}
          </AutosuggestDataProvider>
        </form>
      </PriceDataProvider>
    );
  }
}

export default withElementEventTracker(
  withMetrics(withI18n(withUserContextProvider(SearchControls))),
);
export { LAYOUT };
