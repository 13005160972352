const MAX_CHILDREN = 5;
const MIN_CHILDREN = 0;
const MAX_CHILD_AGE = 18;
const MIN_CHILD_AGE = 0;

const areChildrenValid = ({ childrenAges }) => {
  if (
    childrenAges.length > MAX_CHILDREN ||
    childrenAges.length < MIN_CHILDREN
  ) {
    return false;
  }

  for (let i = 0; i < childrenAges.length; i += 1) {
    if (
      !isFinite(childrenAges[i]) || // eslint-disable-line no-restricted-globals
      parseInt(childrenAges[i], 10) > MAX_CHILD_AGE ||
      parseInt(childrenAges[i], 10) < MIN_CHILD_AGE
    ) {
      return false;
    }
  }

  return true;
};

export {
  MAX_CHILDREN,
  MAX_CHILD_AGE,
  MIN_CHILDREN,
  MIN_CHILD_AGE,
  areChildrenValid,
};
