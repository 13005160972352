import getDeviceType from '../../components/get-device-type';
import objValues from '../../utils/object/obj-values';
import {
  AUTOSUGGEST_TELEMETRY_SCHEMA_NAME,
  AUTOSUGGEST_MINIEVENT_NAME,
} from '../minievents/autosuggest';
import {
  EXPERIMENT_CHOKE_POINT_SCHEMA_NAME,
  EXPERIMENT_CHOKE_POINT_MINIEVENT_NAME,
} from '../minievents/choke-point';
import { ACTION_TYPE, DEVICE } from '../minievents/constants';
import {
  HOTELS_FRONTEND_SCHEMA_NAME,
  HOTELS_FRONTEND_MINIEVENT_NAME,
} from '../minievents/hotels-action';
import {
  HOTELS_SEEN_SCHEMA_NAME,
  HOTELS_SEEN_MINIEVENT_NAME,
} from '../minievents/hotels-seen';

import type { DeviceShape } from '../../components/types';
import type {
  ElementEventTrackerReturn,
  EventProps,
  OneOfMessage,
  ObserverClientReturn,
  ChockPointMessage,
} from '../types';

const GRAPPLER_EVENT = 'grappler-track';

type Props = {
  impressionId?: string;
  device?: DeviceShape;
  trafficSource?: string;
  utmSource?: string;
  observerClient: ObserverClientReturn;
  pageType: string;
};

const ElementEventTracker = ({
  device,
  impressionId,
  observerClient,
  pageType,
  trafficSource,
  utmSource,
}: Props): ElementEventTrackerReturn => {
  const miniEventActions = objValues(ACTION_TYPE);

  const trackMiniEvent = ({
    consentRequired = true,
    eventName,
    fullSchemaName,
    message,
  }: EventProps) => {
    observerClient.track(GRAPPLER_EVENT, {
      eventName,
      fullSchemaName,
      message,
      consentRequired,
      isMiniEvent: true,
    });
  };

  const trackHotelsAction = (name: string, props: OneOfMessage = {}) => {
    if (!miniEventActions.includes(name)) {
      throw new Error(
        'Actions on Element Events should be from the ACTION_TYPE object',
      );
    }

    trackMiniEvent({
      eventName: HOTELS_FRONTEND_MINIEVENT_NAME,
      fullSchemaName: HOTELS_FRONTEND_SCHEMA_NAME,
      message: {
        action_type: name,
        is_bot: device ? device.isRobot : true,
        traffic_source: trafficSource,
        utm_source: utmSource,
        // @ts-ignore
        device: DEVICE[getDeviceType(device).toUpperCase()],
        impression_id: impressionId,
        ...props,
      },
    });
  };

  const trackExperimentChockPoint = (message: ChockPointMessage) => {
    trackMiniEvent({
      eventName: EXPERIMENT_CHOKE_POINT_MINIEVENT_NAME,
      fullSchemaName: EXPERIMENT_CHOKE_POINT_SCHEMA_NAME,
      message,
    });
  };

  const trackHotelsSeen = (props: OneOfMessage = {}) => {
    trackMiniEvent({
      eventName: HOTELS_SEEN_MINIEVENT_NAME,
      fullSchemaName: HOTELS_SEEN_SCHEMA_NAME,
      message: {
        traffic_source: trafficSource,
        impression_id: impressionId,
        // @ts-ignore
        device: DEVICE[getDeviceType(device).toUpperCase()],
        ...props,
      },
    });
  };

  const trackAutoSuggest = (props: OneOfMessage = {}) => {
    trackMiniEvent({
      eventName: AUTOSUGGEST_MINIEVENT_NAME,
      fullSchemaName: AUTOSUGGEST_TELEMETRY_SCHEMA_NAME,
      message: {
        // @ts-ignore
        platform: DEVICE[getDeviceType(device).toUpperCase()],
        page_type: pageType,
        ...props,
      },
    });
  };

  return {
    trackMiniEvent,

    trackHotelsAction,

    trackExperimentChockPoint,

    trackHotelsSeen,
    // @ts-ignore
    trackAutoSuggest,
  };
};

export default ElementEventTracker;
