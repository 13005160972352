import { CALENDAR_SELECTION_TYPE } from '@skyscanner/backpack-web/bpk-component-calendar';
import BpkDatepicker from '@skyscanner/backpack-web/bpk-component-datepicker';

import {
  minCheckInDate,
  minCheckOutDate,
  maxCheckInDate,
  maxCheckOutDate,
} from '../../../../../../common/src/services/guest-stay';
import { withFeatureTests } from '../../../../skyscanner-application/feature-tests';
import { DATE_FORMAT, withI18n } from '../../../../skyscanner-application/i18n';
import { getApplicationElement } from '../../../Common/ApplicationElements';

import type { I18nShape } from '../../../../skyscanner-application/i18n';
import type { FeatureTestsType } from '../../../../skyscanner-application/types';
import type { CalendarPriceDataShape, Maybe } from '../../../types';

type Props = {
  i18n: I18nShape;
  id: string;
  date: Date;
  startDate: Date;
  endDate: Date;
  dateData: Maybe<CalendarPriceDataShape>;
  featureTests: FeatureTestsType;
  isCheckOut?: boolean;
  onDateSelect: Function;
};

const DatePicker = ({
  date,
  dateData,
  endDate,
  featureTests,
  i18n: { formatDate, getDaysOfWeek, getFirstDay, translate },
  id,
  isCheckOut = false,
  onDateSelect,
  startDate,
  ...rest
}: Props) => {
  const selectionConfiguration = {
    type: CALENDAR_SELECTION_TYPE.single,
    date,
  };

  const handleDateSelect = (newDate: Date) => {
    onDateSelect(newDate);
  };

  const formatDateFull = (dt: Date) => formatDate(dt, DATE_FORMAT.FULL);

  const formatMonth = (dt: Date) => formatDate(dt, DATE_FORMAT.YEAR_MONTH);

  const daysOfWeek = getDaysOfWeek();
  const weekStartsOn = getFirstDay();

  let title;
  let minDate;
  let maxDate;
  if (isCheckOut) {
    title = translate('SearchControls_label_CheckOut');
    minDate = minCheckOutDate();
    maxDate = maxCheckOutDate();
  } else {
    title = translate('SearchControls_label_CheckIn');
    minDate = minCheckInDate();
    maxDate = maxCheckInDate();
  }

  return (
    <BpkDatepicker
      id={id}
      title={title}
      weekStartsOn={weekStartsOn}
      daysOfWeek={daysOfWeek}
      minDate={minDate}
      maxDate={maxDate}
      closeButtonText={translate('Modal_close')}
      changeMonthLabel={translate('HotelStay_label_changeMonth')}
      formatDate={formatDate}
      formatMonth={formatMonth}
      formatDateFull={formatDateFull}
      onDateSelect={handleDateSelect}
      selectionConfiguration={selectionConfiguration}
      getApplicationElement={getApplicationElement}
      {...rest}
    />
  );
};

export default withFeatureTests(withI18n(DatePicker));
