import { CUG_TYPE } from './constants';

import type { HotelsSeenMessage } from '../types';

export const HOTELS_SEEN_SCHEMA_NAME = 'hotels.SearchResultSeenEvent';
export const HOTELS_SEEN_MINIEVENT_NAME = 'HotelsSearchResultSeen';

export const buildHotelsSeenMessage = ({
  audienceId,
  currency,
  hotel,
  hotelIndex,
  isLowest,
  pageIndex = 0,
  searchId,
  traceInfo,
  upSortHotels,
}: HotelsSeenMessage) => {
  const { id, priceInfo: { cug, partnerId, price } = {} } = hotel;

  const isUpSortHotel = upSortHotels
    ? upSortHotels.split(',').includes(id)
    : false;
  const cugType =
    (cug && cug.type && cug.type.toUpperCase()) || 'UNSET_CLOSED_USER_GROUP';

  return {
    hotel_id: id,
    search_id: searchId,
    hotel_sorting_position: parseInt(hotelIndex, 10),
    hotel_sorting_page_num: pageIndex,
    has_cug_deal: !!cug,
    // @ts-ignore
    cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : 'UNSET_CLOSED_USER_GROUP',
    cug_deal_percentage: cug ? cug.discountPercentage : 0,
    is_up_sort_hotel: isUpSortHotel,
    partner_id: partnerId,
    price: price && {
      currency,
      amount: Math.round(price),
      unit: 'WHOLE',
    },
    trace_info: isUpSortHotel ? traceInfo : null,
    is_lowest: isLowest,
    audience_id: isUpSortHotel ? audienceId : null,
  };
};
