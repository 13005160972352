import { createContext } from 'react';
import type { ComponentType } from 'react';

import displayName from '../../display-name';

import type { CalendarPriceDataShape, Maybe } from '../../types';

const PriceDataContext = createContext(null);

const withPriceData = <P extends {}>(Component: ComponentType<P>) => {
  const component = (props: Omit<P, 'priceData'>) => (
    <PriceDataContext.Consumer>
      {(priceData: Maybe<CalendarPriceDataShape>) => (
        <Component priceData={priceData?.value ?? null} {...(props as P)} />
      )}
    </PriceDataContext.Consumer>
  );
  component.displayName = displayName(Component);

  return component;
};

export { withPriceData, PriceDataContext };
