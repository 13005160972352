import { Component } from 'react';

import differenceInDays from 'date-fns/differenceInDays';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import startOfMinute from 'date-fns/startOfMinute';

import {
  BpkCalendarGridHeader,
  composeCalendar,
  withCalendarState,
} from '@skyscanner/backpack-web/bpk-component-calendar';
import {
  BpkScrollableCalendarGridList,
  CALENDAR_SELECTION_TYPE,
} from '@skyscanner/backpack-web/bpk-component-scrollable-calendar';

import {
  minCheckInDate,
  maxCheckOutDate,
} from '../../../../common/src/services/guest-stay';
import { parseDate } from '../../../../common/src/utils/machine-date-utils';
import logger from '../../services/logger';
import { DATE_FORMAT, withI18n } from '../../skyscanner-application/i18n';

import CalendarDate from './CalendarDate';

import type { I18nShape } from '../../skyscanner-application/i18n';

import STYLES from './ScrollableRangeCalendar.module.scss';

const convertToDateTime = (date: number | Date, time: string): Date => {
  const [hours, minutes] = time.split(':');

  return setMinutes(setHours(date, parseInt(hours, 10)), parseInt(minutes, 10));
};

export const truncDate = (dateParam: Date | null) => {
  if (!dateParam) return dateParam;
  let date = dateParam;
  if (!isDate(dateParam)) {
    date = parseISO(dateParam.toString());
  }
  if (!isValid(date)) {
    logger.warn('The param is invalid Date in ScrollableRangeCalendar', {
      date: JSON.stringify(dateParam),
    });
    return null;
  }
  return startOfMinute(convertToDateTime(date, '00:00'));
};

type Props = {
  i18n: I18nShape;
  onDateRangeChange: Function;
  startDate: Date;
  endDate: Date;
};

type State = {
  selectionConfiguration: {
    type: typeof CALENDAR_SELECTION_TYPE.range;
    startDate: Date | null;
    endDate: Date | null;
  };
};

const RangeCalendar = withCalendarState(
  composeCalendar(
    null,
    BpkCalendarGridHeader,
    BpkScrollableCalendarGridList,
    CalendarDate,
  ),
);

class ScrollableRangeCalendar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { endDate, startDate } = props;
    this.state = {
      selectionConfiguration: {
        type: CALENDAR_SELECTION_TYPE.range,
        startDate: truncDate(startDate || null),
        endDate: truncDate(endDate || null),
      },
    };
  }

  onDateSelect = (startDate: Date, endDate?: Date) => {
    if (startDate) {
      let selectedStartDate = startDate;
      let selectedEndDate = endDate || null;
      if (!endDate) {
        selectedEndDate = null;
      } else if (differenceInDays(startDate, parseDate(endDate)) < -30) {
        selectedStartDate = endDate;
        selectedEndDate = null;
      }
      this.setState({
        selectionConfiguration: {
          type: CALENDAR_SELECTION_TYPE.range,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        },
      });
      this.props.onDateRangeChange(selectedStartDate, selectedEndDate);
    }
  };

  formatDateFull = (date: Date) =>
    this.props.i18n.formatDate(date, DATE_FORMAT.FULL);

  formatMonth = (date: Date) =>
    this.props.i18n.formatDate(date, DATE_FORMAT.YEAR_MONTH);

  getSelectedDate = () => {
    const { endDate, startDate } = this.state.selectionConfiguration;
    return endDate || startDate;
  };

  render() {
    const { startDate } = this.state.selectionConfiguration;
    const {
      i18n: { getDaysOfWeek, getFirstDay },
    } = this.props;

    const minDate = minCheckInDate();
    const maxDate = maxCheckOutDate();

    const calendarProps = {
      className: STYLES.ScrollableRangeCalendar__inner,
      daysOfWeek: getDaysOfWeek(),
      markToday: false,
      selectionConfiguration: this.state.selectionConfiguration,
      onDateSelect: this.onDateSelect,
      weekDayKey: 'nameNarrow',
      enableSelection: true,
      formatDate: this.formatDateFull,
      formatDateFull: this.formatDateFull,
      formatMonth: this.formatMonth,
      id: 'calendar',
      initialSelectedDate: null,
      minDate,
      maxDate,
      date: startDate,
      weekStartsOn: getFirstDay(),
      DateComponent: CalendarDate,
      fixedWidth: false,
      selectTodayDate: false,
      selectedDate: this.getSelectedDate(),
    };

    return <RangeCalendar {...calendarProps} />;
  }
}

export default withI18n(ScrollableRangeCalendar);
